.sidebar {
  .sidebar-top {
  
    .user-menu {

      .user-menu-toggle {
        @apply w-auto flex items-start cursor-pointer;

        .user-menu-avatar {
          @apply relative;

          .user-menu-toggle-icon {
            background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
            color: rgb(var(--surfaces-500) / var(--tw-text-opacity));
            @apply z-90 w-6 h-6 rounded-full absolute -right-4 top-8px flex items-center justify-start;
          }// .user-menu-toggle-icon

          .avatar.account-icon {
          }// .avatar.account-icon 

        }// .user-menu-avatar

        .account-name {
          @apply flex-1 ml-3 font-medium leading-tight mt-9px;
        }// .account-name

        &:hover {
          .user-menu-avatar {

            .user-menu-toggle-icon {
              @apply text-black;
            }// .user-menu-toggle-icon

            .avatar.account-icon {
            }// .avatar.account-icon 

          }// .user-menu-avatar

          .account-name {
            @apply text-black;
          }// .account-name
        }// .user-menu-toggle:hover

      }// .user-menu-toggle

      .dropdown-menu {
        min-width: 16rem;
        max-width: 20rem;
        @apply shadow-xl;

        .account-switcher {
          .account-switcher-link {
            .avatar {
              span.initials {
                @apply text-gray-500;
              }
            }
            svg {
              @apply text-black opacity-100;
            }
          }// .account-switcher-link
        }// .account-switcher

        .respondent-menu {
          
          .respondent-links {
            
            a {
              svg {
                color: rgb(var(--primary-600) / var(--tw-text-opacity));
                @apply opacity-100;
              }
              span {
                color: rgb(var(--primary-600) / var(--tw-text-opacity));
              }

              &:hover {
                @apply bg-transparent;

                svg {
                  @apply text-black;
                }
                span {
                  @apply text-black;
                }
              }
            }

          }// .respondent-links

          a.respondent-cta {

            &:hover {
              background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
            }
          }

        }// .respondent-menu

      }// .user-menu .dropdown-menu

    }// .user-menu

  }// .sidebar-top

  &.account-present {

    .sidebar-top {
      .user-menu {
        .user-menu-toggle {
          .avatar.account-icon {
            background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));

            .initials {
              color: rgb(var(--secondary-600) / var(--tw-text-opacity));
              @apply font-normal;
            }
          }// .avatar.account-icon 
        }
      }
    }

  }// .sidebar.account-present

  &.no-account-present {

    .sidebar-top {
      .user-menu {
        .user-menu-toggle {
          .avatar.account-icon {
            background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));

            .initials {
              color: rgb(var(--secondary-600) / var(--tw-text-opacity));
              @apply font-medium;
            }
          }// .avatar.account-icon 
        }
      }
    }

  }// .sidebar.no-account-present  

  &.sidebar-open {
    .sidebar-inner-container {
      .sidebar-top {

        .user-menu {
          @apply ml-2;
          
          .user-menu-toggle {

            .account-name {
            }// .account-name

            .user-menu-toggle-icon {
            }// .user-menu-toggle-icon

          }// .user-menu-toggle

          .dropdown-menu {
            @apply -left-1 -top-2;
          }// .dropdown-menu

        }// .user-menu

      }// .sidebar-top
    }// .sidebar-inner-container
  }// .sidebar.sidebar-open

  &.sidebar-closed {
    .sidebar-inner-container {
      .sidebar-top {
        
        .user-menu {
          @apply text-center; 

          .user-menu-toggle {
            @apply inline-flex;

            .user-menu-avatar {
              .user-menu-toggle-icon {
                @apply hidden;
              }
            }// .user-menu-avatar

            .account-name {
              @apply hidden;
            }// .account-name

          }// .user-menu-toggle

          .dropdown-menu {
            @apply -left-2 -top-2;
            width: 18rem;
          }// .dropdown-menu

        }// .user-menu

      }// .sidebar-top

    }// .sidebar-inner-container
  }// .sidebar.sidebar-closed
}// .sidebar 