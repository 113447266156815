.alert {
  @apply bg-gray-50 text-gray-600 p-4 rounded font-medium;

  &.alert-small {
    @apply py-2 px-3;
  }

  &.alert-info {
    @apply bg-gray-50 text-gray-600;
  }

  &.alert-success {
    color: rgb(var(--secondary-600) / var(--tw-text-opacity));
    background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
  }

  &.alert-error {
    @apply bg-red-100 text-red-600;
  }

  &.alert-warning {
    @apply bg-yellow-100 text-yellow-600;
  }
}
