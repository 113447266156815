#conversations-filters {

  &.active {

    #conversations-filters-toggle {
      @apply bg-gray-100 text-black hover:bg-gray-200;
    }

  }

}