.modal {

  .modal-nav {

    .modal-nav-link {
      svg {
        @apply text-surfaces-500 opacity-60;
      }

      &:hover {
        @apply bg-surfaces-500 bg-opacity-20;
        
        svg {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }
      }
      &.active {
        @apply bg-white text-black font-semibold border md:border-t md:border-l md:border-r md:border-b-0 border-surfaces-500 border-opacity-20 hover:bg-white;

        > svg {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }
      }
    }// .modal-nav-link

  }// .modal-nav

  .modal-subnav {

    .modal-subnav-link {
      @apply text-gray-400;

      svg {
        @apply text-gray-300;
      }

      &:hover {
        @apply text-black;

        svg {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }
      }

      &.active {
        @apply text-black font-semibold bg-white relative top-2px pb-0 border-b-2 border-black;
        
        &:last {
          @apply top-1px;
        }
        
        svg {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }

        .link-content {
          @apply relative -top-3px;
        }
      }// .modal-subnav-link.active

    }// .modal-subnav-link

    .subnav-breadrumbs { // used on workflow tab's subnav

      .modal-subnav-link {

        &.active {
          @apply text-black top-0 border-b-0;
        }

      }// .subnav-breadrumbs .modal-subnav-link

    }// .subnav-breadrumbs

  }// .modal-subnav

}