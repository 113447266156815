.tabs {

  .tab {
    @apply hover:bg-gray-200 hover:bg-opacity-60;
  
    &.active {
      @apply bg-white text-black font-semibold border md:border-t md:border-l md:border-r md:border-b-0 border-gray-200 hover:bg-white;
    }

  }

}