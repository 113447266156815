form#new_message,
form#new-conversation {

  &.draft-message {

    .new-msg-controls {
      border-color: rgb(var(--secondary-600) / var(--tw-border-opacity));
      @apply border-opacity-20;

      .new-msg-controls-content {
        background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
        color: rgb(var(--secondary-600) / var(--tw-text-opacity));
        @apply bg-opacity-70;

        .new-msg-control-btn {
          &:hover,
          &.active {
            border-color: rgb(var(--secondary-600) / var(--tw-border-opacity));
            @apply border-opacity-20;
          }

        }// .new-msg-control-btn

      }// .new-message-controls-content

    }// .new-msg-controls

    .message-border {
      border-color: rgb(var(--secondary-600) / var(--tw-border-opacity));
      @apply border-opacity-20;

      .message-body {

        .kickoff-recording,
        .preview-media,
        .message-block {
          background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
          color: rgb(var(--secondary-600) / var(--tw-text-opacity));
          @apply bg-opacity-70;
        }// .message-text

      }// .message-body

    }// .message-border

  }// form#new_message.internal-message

}// form#new_message


// Existing messages
#messages {

  .message {

    &.draft-message {

      .message-border {
        border-color: rgb(var(--secondary-600) / var(--tw-border-opacity));
        @apply border-opacity-20;

        .message-body {

          .message-block {
            color: rgb(var(--secondary-600) / var(--tw-text-opacity));
            background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
            @apply bg-opacity-70;

            &.update-message-block {

              .btn {
                background-color: rgb(var(--secondary-600) / var(--tw-bg-opacity));
                @apply bg-opacity-70;
              }
            }// .message-block.update-message-block

          }// .message-block

        }// .message-body

      }// .message-border

    }// .message.internal-message

  }// .message

} // #messages
