.workflow-form {
  
  .workflow-form-nav {
    @apply font-medium pt-2 px-2;

    .workflow-form-nav-item {
      @apply flex-shrink items-start py-2 px-4 mb-1.5 border border-transparent rounded cursor-pointer;

      // Tailwind 3 couldn't compile this.  If it's still needed, then refactor to a different solution
      // &.hidden {
      //   display: none;
      // }

      .workflow-form-nav-icon {
        @apply text-surfaces-500 opacity-60 mt-1px mr-2;
      }// .workflow-form-nav-title

      &:hover {
        @apply bg-surfaces-500 bg-opacity-20;

        .workflow-form-nav-icon {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }// .workflow-form-nav-title

        .workflow-form-nav-title {
          @apply text-black whitespace-nowrap;
        }// .workflow-form-nav-title
      }
      
      .workflow-form-nav-title {
        @apply text-surfaces-500;
      }// .workflow-form-nav-title

      .workflow-form-nav-description {
        @apply hidden text-xs text-surfaces-500 leading-tight my-1;    
      }// .workflow-form-nav-description


      &.active {
        @apply bg-surfaces-500 bg-opacity-20;

        .workflow-form-nav-icon {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }// .workflow-form-nav-title

        .workflow-form-nav-title {
          @apply text-black;
        }// .workflow-form-nav-title

        .workflow-form-nav-description {
          @apply block text-surfaces-500;
        }// .workflow-form-nav-description
      }

    }// .workflow-form-nav-item


  }// .workflow-form-nav

}// .workflow-form