@charset "UTF-8";
/* Trix 1.2.2 Copyright © 2019 Basecamp, LLC http://trix-editor.org
/*/

 .hide-trix-toolbar trix-toolbar {
     display: none;
}

.display-link-trix-tool .trix-button-row{
  display: none;
}

 trix-toolbar * {
     box-sizing: border-box;
}
 trix-toolbar .trix-button-row {
     display: flex;
     flex-wrap: nowrap;
     justify-content: space-between;
     position: relative;
     left: -3px;
}
 trix-toolbar .trix-button-group {
     @apply mb-2 flex rounded space-x-1;
}
 trix-toolbar .trix-button-group:not(:first-child) {
     // margin-left: 1.5vw;
}
 @media (max-device-width: 768px) {
     trix-toolbar .trix-button-group:not(:first-child) {
         margin-left: 0;
    }
}
 trix-toolbar .trix-button-group-spacer {
     flex-grow: 1;
}
 @media (max-device-width: 768px) {
     trix-toolbar .trix-button-group-spacer {
         display: none;
    }
}
 trix-toolbar .trix-button {
     @apply relative text-gray-600 text-sm px-3 py-4;
}
 trix-toolbar .trix-button:hover {
  @apply text-black;
     &::before {
      opacity: 1;
     }
}
 trix-toolbar .trix-button:focus {
     @apply z-10 outline-none text-black;
}
 trix-toolbar .trix-button:not(:first-child) {
     // border-left: 1px solid #ccc;
     // @apply rounded-none border-l-0;
}
 trix-toolbar .trix-button:last-child {
     // @apply rounded-r;
}
 trix-toolbar .trix-button.trix-active {
     @apply z-10 text-black;
}
 trix-toolbar .trix-button:not(:disabled) {
     cursor: pointer;
}
 trix-toolbar .trix-button:disabled {
     color: rgba(0, 0, 0, 0.125);
}
 @media (max-device-width: 768px) {
     trix-toolbar .trix-button {
         letter-spacing: -0.01em;
         padding: 0 0.3em;
    }
}
 trix-toolbar .trix-button--icon {
     font-size: inherit;
     width: 2em;
     height: 1.1em;
     max-width: calc(0.8em + 4vw);
     text-indent: -9999px;
}
 @media (max-device-width: 768px) {
     trix-toolbar .trix-button--icon {
         height: 2em;
         max-width: calc(0.8em + 3.5vw);
    }
}
 trix-toolbar .trix-button--icon::before {
     display: inline-block;
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     opacity: 0.4;
     content: "";
     background-position: center;
     background-repeat: no-repeat;
     // background-size: contain;
}
 @media (max-device-width: 768px) {
     trix-toolbar .trix-button--icon::before {
         right: 6%;
         left: 6%;
    }
}
 trix-toolbar .trix-button--icon.trix-active::before {
     opacity: 1;
}
 trix-toolbar .trix-button--icon:disabled::before {
     opacity: 0.125;
}
 trix-toolbar .trix-button--icon-attach::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
     top: 8%;
     bottom: 4%;
}
 trix-toolbar .trix-button--icon-bold::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.8c1-.7%201.6-1.8%201.6-2.8a4%204%200%200%200-4-4H7v14h7c2.1%200%203.7-1.7%203.7-3.8%200-1.5-.8-2.8-2.1-3.4zM10%207.5h3a1.5%201.5%200%201%201%200%203h-3v-3zm3.5%209H10v-3h3.5a1.5%201.5%200%201%201%200%203z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-italic::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.2l-3.4%208H6v3h8v-3h-2.2l3.4-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-link::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.7a4.3%204.3%200%200%201%200-6.07l3.37-3.37a4.26%204.26%200%200%201%206.07%200%204.3%204.3%200%200%201%200%206.06l-1.96%201.72a.91.91%200%201%201-1.3-1.3l1.97-1.71a2.46%202.46%200%200%200-3.48-3.48l-3.38%203.37a2.46%202.46%200%200%200%200%203.48.91.91%200%201%201-1.3%201.3z%22%2F%3E%3Cpath%20d%3D%22M4.25%2019.46a4.3%204.3%200%200%201%200-6.07l1.93-1.9a.91.91%200%201%201%201.3%201.3l-1.93%201.9a2.46%202.46%200%200%200%203.48%203.48l3.37-3.38c.96-.96.96-2.52%200-3.48a.91.91%200%201%201%201.3-1.3%204.3%204.3%200%200%201%200%206.07l-3.38%203.38a4.26%204.26%200%200%201-6.07%200z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-strike::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-quote::before {
     background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-heading-1::before {
     background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-code::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-bullet-list::before {
     background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-number-list::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-undo::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-redo::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-decrease-nesting-level::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-button--icon-increase-nesting-level::before {
     background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}
 trix-toolbar .trix-dialogs {
     position: relative;
}
 trix-toolbar .trix-dialog {
     z-index: 5;
     @apply absolute top-7 left-6 right-0 bg-white rounded-lg shadow-xl p-6 border border-gray-200 border-opacity-80;
}
 trix-toolbar .trix-input--dialog {
     font-size: inherit;
     font-weight: normal;
     padding: 0.5em 0.8em;
     margin: 0 10px 0 0;
     border-radius: 3px;
     border: 1px solid #bbb;
     background-color: #fff;
     box-shadow: none;
     outline: none;
     -webkit-appearance: none;
     -moz-appearance: none;
}
 trix-toolbar .trix-input--dialog.validate:invalid {
     box-shadow: #F00 0px 0px 1.5px 1px;
}
 trix-toolbar .trix-button--dialog {
     font-size: inherit;
     padding: 0.5em;
     border-bottom: none;
}
 trix-toolbar .trix-dialog--link {
     max-width: 600px;
}
 trix-toolbar .trix-dialog__link-fields {
     display: flex;
     align-items: baseline;
}
 trix-toolbar .trix-dialog__link-fields .trix-input {
     flex: 1;
     @apply rounded-full border border-gray-200 font-medium px-4;

     &:active,
     &:focus {
        border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
        @apply border-2 ring-0;
     }
}
 trix-toolbar .trix-dialog__link-fields .trix-button-group {
     @apply space-x-2 ml-4 mb-0;

     .trix-button.trix-button--dialog {
        @apply bg-gray-100 text-black border border-gray-50 font-medium px-5;

        &:hover {
            @apply text-black;
        }
     }
}

trix-toolbar [data-trix-dialog][data-trix-active] {
    width: 600px;
}

.small-trix-toolbar trix-toolbar {
  .trix-button-group {
    @apply space-x-1;
  }

  .trix-button {
    @apply p-0 border-0;

    &.trix-button--icon-undo,
    &.trix-button--icon-redo {
      display: none;
    }
  }

  .trix-button--icon {
    @apply w-6;
  }

  .trix-button--icon-attach::before {
    @apply transform rotate-90 h-8 -top-8px left-9px;
  }
 }

 .hide-attachment-button trix-toolbar .trix-button-group.trix-button-group--file-tools{
    display: none;
 }

 .autosave-trix {
   trix-toolbar {
     @apply inline-flex bg-gray-50 rounded pt-2 px-2 border border-gray-100;
   }
 }
