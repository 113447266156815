.video-js {
  font-size: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;

  .vjs-big-play-button {
    $big-play-width: 2em;
    $big-play-height: 2em;
    line-height: $big-play-height;
    height: $big-play-height;
    width: $big-play-width;
    left: 50%;
    top: 50%;
    margin-left: -($big-play-width / 2);
    margin-top: -($big-play-height / 2);
    @apply border-0 bg-black bg-opacity-10 rounded-full;

    @media (max-width: 600px) {
      width: 3rem;
      height: 3rem;
      margin-left: -(3rem / 2);
      margin-top: -(3rem / 2);

      .vjs-icon-placeholder:before {
        font-size: 2rem;
        margin-top: -1.7rem;
      }
    } // max-width: 600px
  }

  .vjs-tech {
    border-radius: 5px;
  }

  &.vjs-audio {
    padding-top: 15%;
    .vjs-big-play-button {
      left: 8%;
    }
  }

  .vjs-control-bar,
  .vjs-menu-button .vjs-menu-content,
  .vjs-volume-panel .vjs-volume-control {
    @apply bg-black z-60;
  }

  .vjs-slider,
  .vjs-volume-bar.vjs-slider-bar {
    @apply bg-gray-200;
  }

  .vjs-volume-bar.vjs-slider-bar.vjs-slider-vertical {
    height: 5.9rem;
  }

  .vjs-volume-level,
  .vjs-play-progress,
  .vjs-slider-bar {
    background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));
  }

  .vjs-playback-rate {
    .vjs-playback-rate-value {
      @apply text-lg flex flex-col justify-center;
    }
    li.vjs-menu-item {
      @apply text-sm text-left px-3 py-1.5 font-medium;
    }
  }

  .vjs-picture-in-picture-control {
    @media (max-width: 640px) {
      display: none;
    } // max-width: 640px
  }

  &:hover {
    .vjs-big-play-button {
      @apply border-0 shadow-lg bg-opacity-100 z-100;
      background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));
    }
  }

  .vjs-poster {
    background-size: cover;
    border-radius: 5px;
  }

  &.audio-player {
    .vjs-poster {
      background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
    }
  }
}

.message {
  &.mobile-recording {
    .video-js {
      .vjs-poster {
        background-size: contain;
      }
    }
  }
}
