.toggle {
  &.disabled {
    .toggle-label {
      @apply opacity-30;
    }
  }
  
  .toggle-container {
    &.disabled {
      @apply opacity-30 ring-0 cursor-default;
    }
    .toggle-handle {
      @apply translate-x-0;
      .on-icon {
        display: none;
      }// .on-icon
      .off-icon {
        display: flex;
      }// .off-icon
    }// .toggle-handle
    .toggle-label {
      &.disabled {
        @apply opacity-30;
      }
    }
  }// .toggle-container

  &.active {
    .toggle-container {
      &.disabled {
        @apply opacity-30 ring-0 cursor-default;
      }
      .toggle-handle {
        @apply translate-x-5;
        .on-icon {
          display: flex;
        }// .on-icon
        .off-icon {
          display: none;
        }// .off-icon
      }// .toggle-handle
    }// .toggle-container
  }// .toggle.active

}// .toggle