// mobile .sidebar is styled in mobile_sidebar.scss

.sidebar {
  background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
  @apply border-r border-surfaces-500 border-opacity-20 relative z-60;

  @media (max-width: 768px) {
    @apply absolute top-0 left-0 w-full z-100 shadow-xl;
  }
  // max-width: 768px

  .close-mobile-sidebar {
    @apply hidden ml-2 mr-4 mt-2;

    @media (max-width: 768px) {
      @apply block;
    }
    // max-width: 768px

  }

  // .close-mobile-sidebar

  .sidebar-top {
    @apply mt-5 flex items-start justify-between;
    // margin-bottom (mb-) is set on each instance of sidebar top.  Usually it should be set to mb-20.)
  }

  // .sidebar-top

  &.account-present {
  }

  // .sidebar.account-present

  &.no-account-present {
  }

  // .sidebar.no-account-present


  &.sidebar-open {
    // .sidebar-open is when the sidebar is in it's maximized state on desktop.
    // We also apply the .sidebar-open class on mobile, and then tweak using media queries within this block.

    @apply px-3;
    min-width: 16rem;
    max-width: 20rem; // If account name is long, give sidebar a little extra width when open

    @media (max-width: 1024px) {
      @apply w-full;
      max-width: none;
      min-width: none;
    }
    // max-width: 1024px

    @media (min-width: 768px) and (max-width: 1024px) {
      @apply absolute top-0 left-0 w-full z-100 shadow-xl;
    }
    // max-width: 768px

    .sidebar-inner-container {
      @apply items-start;

      .sidebar-toggle {
        @media (max-width: 1024px) {
          @apply hidden;
        }
        // max-width: 1024px
      }

      // .sidebar-toggle

      .close-mobile-sidebar {
        @media (min-width: 768px) and (max-width: 1024px) {
          @apply block;
        }
        // max-width: 768px
      }

      // .close-mobile-sidebar

      .sidebar-top {
        @apply w-full;
      }

      // .sidebar-top

      .sidebar-sections {
        // .sidebar-sections are containers for the top-most sections in the global sidebar (containers for "Conversations", "Contacts", "Library", etc.)
        @apply items-start w-full;

        .sidebar-section {
          @apply rounded-full border border-transparent px-4 w-full;

          &.x-padding-0 {
            @apply px-0;
          }

          .section-settings {
            // Used if the section has special settings shown in the top-right corner of the section.  Example: Conversations index settings.
            @apply absolute top-4.25 right-3 z-100;

            .section-settings-icon {
              @apply w-5 h-5 rounded flex items-center justify-center;
              svg {
                @apply w-4.25 text-gray-400 cursor-pointer;
              }
            }

            &:hover {
              .section-settings-icon {
                svg {
                  @apply text-black;
                }
              }
            }

            // .section-settings:hover
          }

          // .section-settings

          .section-link {
            // A main link at the top-level of the sidebar ("Conversations", "Contacts", "Library", etc.)
            .section-link-icon {
              @apply w-8;
              svg {
                @apply text-surfaces-500 opacity-60;

                &.fa-calendar-days {
                  @apply relative left-3px;
                }
              }
            }

            // .section-link-icon

            .section-link-text {
              @apply text-surfaces-500 opacity-100;
            }

            // .section-link-text

            &:hover {

              .section-link-icon {
                svg {
                  color: rgb(var(--primary-600) / var(--tw-text-opacity));
                  @apply opacity-100;
                }
              }

              // .section-link-icon

              .section-link-text {
                @apply text-black;
              }

              // .section-link-text

            }

            // .section-link:hover

          }

          // .section-link

          .section-content {
            // .section-content contains all content inside of a main section, below the section's main link.
            // Hidden by default, and shown the section is active.
            @apply hidden;
          }

          // .section-content

          &.has-content {
            @apply rounded-lg;

            .section-content {
              @apply hidden pl-4.5 pt-3 pb-1 space-y-4;

              .section-subnav {
                // .section-subnav contains a set of sub navigation links within a section's content.
                // There can be multiple .section-subnav within a section's content.
                @apply space-y-1;

                &.scrollable {
                  @apply max-h-72 overflow-y-auto;
                }

                .section-subnav-link {
                  // Use .section-subnav-link when displaying static links (not tags.  for displaying a tags list, see sidebar_tags.scss)
                  // .section-subnav-links are navigation links shown within a section's content, and can be used in multiple indent levels within a sectino's content.
                  @apply flex items-start rounded-full py-1 px-3 border border-transparent;

                  svg.subnav-link-icon {
                    @apply w-4 mr-2 text-gray-300;

                    &.active-icon {
                      @apply hidden;
                    }

                    &.inactive-icon {
                      @apply block;
                    }
                  }

                  .section-subnav-link-text {
                    @apply text-gray-500 text-sm font-medium;
                  }

                  &:hover,
                  &.active {

                    svg.subnav-link-icon {
                      color: rgb(var(--primary-600) / var(--tw-text-opacity));
                    }

                    .section-subnav-link-text {
                      @apply text-black;
                    }
                  }

                  // .section-subnav-link-text:hover, .section-subnav-link-text.active

                  &.active {
                    @apply bg-gray-50 border-gray-100 border-opacity-50;

                    svg {
                      &.active-icon {
                        @apply block;
                      }

                      &.inactive-icon {
                        @apply hidden;
                      }
                    }
                  }

                  // .section-subnav-link-text.active

                  // Tailwind 3 couldn't compile this.  If it's still needed, then refactor to a different solution
                  // &.hidden {
                  //   display: none;
                  // }// .section-subnav-link-text.active

                }

                // .section-subnav-link

                .sub-links {
                  // Used to contain (and indent) a 2nd level of .section-subnav-links.  
                  // Example: When a space_container has 2 or more spaces, we show its child spaces links in a .sub-links container below the parent space_container link. 
                  @apply pl-6 py-1;

                  .section-subnav-link {

                    .subnav-link-icon {
                      @apply w-3.5;
                    }

                    // .section-subnav-link-icon

                    .section-subnav-link-text {
                      @apply text-xs;
                    }

                    // .section-subnav-link-text

                  }

                  // .section-subnav-link

                }

                // .sub-links

              }

              // .section-subnav

              &.has-sub-sections {
                // Add .has-sub-sections class to .section-content when this section has 2 or more sub-sections, each with an open/close toggle and header
                // Examples:  
                //    Conversations > Tags & Intake Pages
                //    Library > Message Templates & Thread Templates
                @apply space-y-4 pt-4 pl-0;

                .section-subnav {
                  @apply pl-2 ml-3px;

                  .sub-section-header-link {
                    // The toggleable header link that toggles open/closed the sub-section.
                    @apply flex items-center cursor-pointer;

                    svg.chevron {
                      @apply w-2.5 mr-2.5 text-gray-300;
                    }

                    .sub-section-header-link-text {
                      @apply text-xs uppercase tracking-wide font-medium text-gray-300;
                    }

                    .popover {
                      @apply hidden;

                      .popover-box {
                        @apply normal-case tracking-normal;
                      }
                    }

                    &:hover {
                      svg.chevron {
                        @apply text-gray-500;
                      }

                      .sub-section-header-link-text {
                        @apply text-gray-500;
                      }

                      .popover {
                        @apply inline-block;
                      }
                    }
                  }

                  // .sub-section-header-link

                  .sub-section-subnav {
                    // Contains the links within a toggleable sub-section.
                    // Hidden until this sub-section has the 'active' class.
                    @apply hidden pl-1 ml-3px mt-2;
                  }

                  &.active {
                    // To apply the 'active' class on sidebar sub-sections, set @sidebar_active_sub_section in the rails controller action for this view.
                    // Set it to the name of this view (example: 'message-templates')
                    // Then on the sidebar .section-subnav element, set its id to id='sidebar-sub-section-{page-name}' (example: id='sidebar-sub-section-message-templates')
                    // sidebar/sidebar_controller.js will apply the active class to the sub-section.

                    .sub-section-header-link {
                      svg.chevron {
                        @apply transform rotate-90;
                      }

                      .sub-section-header-link-text {
                      }
                    }

                    // .sub-section-header-link

                    .sub-section-subnav {
                      @apply block;
                    }
                  }

                  // .section-subnav

                }

                // .section-subnav

              }

              // .section-content.has-sub-sections

            }

            // .section-content

          }

          // .sidebar-section.has-content

          &.active {
            // To apply the 'active' class on sidebar items, set @sidebar_active_item in the rails controller action for this view.
            // Set it to the name of this view (example: 'conversations')
            // Then on the sidebar link, set its id to id='sidebar-link-{page-name}' (example: id='sidebar-link-conversations')
            // sidebar/sidebar_controller.js will apply the active class to the link.

            @apply bg-white border-surfaces-500 border-opacity-20 py-2;

            &.has-content {
              @apply py-4;
            }

            .section-link {

              .section-link-icon {
                svg {
                  color: rgb(var(--primary-600) / var(--tw-text-opacity));
                  @apply opacity-100;
                }
              }

              // .section-link-icon

              .section-link-text {
                @apply text-black;
              }

              // .section-link-text

            }

            // .section-link

            .section-content {
              @apply block;
            }

            // .section-content
          }

          // .sidebar-section.active

        }

        // .sidebar-section

      }

      // .sidebar-sections

    }

    // .sidebar-inner-container

  }

  // .sidebar.sidebar-open

  &.sidebar-closed {
    @apply px-2 w-20;

    .sidebar-inner-container {
      @apply items-center;

      .sidebar-toggle {
        .sidebar-toggle-icon {
          @apply transform rotate-180;
        }
      }

      // .sidebar-toggle

      .sidebar-top {

        .user-events-menu {
          @apply hidden;
        }

      }

      // .sidebar-top

      .sidebar-sections {
        @apply w-full;

        .sidebar-section {
          @apply h-8 ml-2;

          .section-settings {
            @apply hidden;
          }

          // .section-settings

          .section-link {
            @apply absolute top-0 left-0 z-100 pl-3 rounded-full border border-transparent;

            .section-link-icon {
              @apply py-2 flex items-center justify-center;

              svg {
                @apply text-surfaces-500 opacity-60;
              }
            }

            // .section-link-icon

            .section-link-text {
              @apply hidden py-2 pr-6 ml-3;
            }

            // .section-link-text

            &:hover {
              @apply w-auto -top-2px bg-white border-surfaces-500 border-opacity-20 shadow-lg;

              .section-link-icon {
                svg {
                  color: rgb(var(--primary-600) / var(--tw-text-opacity));
                  @apply opacity-100;
                }
              }

              // .section-link-icon

              .section-link-text {
                @apply block;
              }

              // .section-link-text

            }

            // .section-link:hover

          }

          // .section-link

          .section-content {
            @apply hidden;
          }

          // .section-content

          &.active {

            .section-link {

              .section-link-icon {
                @apply relative -left-2.5 -top-2px rounded-full bg-white border border-surfaces-500 border-opacity-20 p-0 w-10 h-10 flex items-center justify-center;

                svg {
                  color: rgb(var(--primary-600) / var(--tw-text-opacity));
                  @apply opacity-100;

                  &.fa-calendar-days {
                    @apply relative left-0;
                  }
                }
              }

              // .section-link-icon

              &:hover {

                .section-link-icon {
                  @apply left-0 top-0 w-auto h-auto bg-transparent border-0;

                  svg {
                    &.fa-calendar-days {
                      @apply relative left-2px;
                    }
                  }
                }

                // .section-link-icon

              }

              // .section-link:hover

            }

            // .section-link

          }

          // .sidebar-section.active

        }

        // .sidebar-section

      }

      // .sidebar-sections

    }

    // .sidebar-inner-container

  }

  // .sidebar.sidebar-closed

  &.always-open {
    // .always-open is used on views where we don't offer toggling of sidebar (example: most settings views) and it's always shown in an open state (unless on mobile screen size)
    @apply px-2;
    min-width: 16rem;
    max-width: 16rem;

    .sidebar-inner-container {

      .sidebar-toggle {
        @apply hidden;
      }

      // .sidebar-toggle

    }

    // .sidebar-inner-container

  }

  // .sidebar.always-open

}

// .sidebar
