@import "tailwindcss/base";
@import "tailwindcss/components";

@import 'components/base';
@import 'components/alert';
@import 'components/avatars';
@import 'components/typography';
@import 'components/buttons';
@import 'components/icons';
@import 'components/forms';
@import 'components/radios-group';
@import 'components/util';
@import 'components/code';
@import 'components/docs';
@import 'components/animation';
@import 'components/tabs';
@import 'components/pagination';
@import 'components/actiontext';
@import 'components/embedded_media';
@import 'components/direct_uploads';
@import 'components/trix_toolbar';
@import 'components/trix_editor';
@import 'components/trix_content';
@import 'components/iframe';
@import 'components/toggle';
@import 'components/dropdown_multi_select';
@import 'custom-utilities';
@import 'components/topbar';
@import 'components/thumbnail-items';
@import 'components/hide_flash';
@import 'components/modal';
@import 'components/modal-nav-subnav';
@import 'components/slack-mentions';
@import 'components/search';
@import 'components/recaptcha';
@import 'components/emoji_picker';
@import 'components/background_images';

@import 'components/commerce/product_pages';
@import 'components/commerce/purchase_flow';
@import 'components/onboarding/initial_onboarding';
@import 'components/onboarding/onboarding_guide';
@import 'components/sidebar/sidebar';
@import 'components/sidebar/user_menu';
@import 'components/sidebar/sidebar_tags';
@import 'components/sidebar/clarityflow_callout';
@import 'components/sidebar/settings_nav';
@import 'components/sidebar/conversation_nav';
@import 'components/sidebar/space_nav';
@import 'components/conversations/filters';
@import 'components/conversations/seen';
@import 'components/message/video_player';
@import 'components/message/ui';
@import 'components/message/internal_messages';
@import 'components/message/draft_messages';
@import 'components/message/message_threads';
@import 'components/message/messages';
@import 'components/message/kickoff_recording';
@import 'components/message/edit_media';
@import 'components/workflows/workflow_forms';
@import 'components/invitations/configure_onboarding';
@import 'components/user_events/user_events_menu';
@import 'components/dropzone';

@import "tailwindcss/utilities";

@layer base {
  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 300;
    src: url("fonts/euclid-circular-b/EuclidCircularB-Light-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-Light-WebS.woff2") format("woff2");
    font-style: normal;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 300;
    src: url("fonts/euclid-circular-b/EuclidCircularB-LightItalic-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-LightItalic-WebS.woff2") format("woff2");
    font-style: italic;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 400;
    src: url("fonts/euclid-circular-b/EuclidCircularB-Regular-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-Regular-WebS.woff2") format("woff2");
    font-style: normal;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 400;
    src: url("fonts/euclid-circular-b/EuclidCircularB-RegularItalic-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-RegularItalic-WebS.woff2") format("woff2");
    font-style: italic;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 500;
    src: url("fonts/euclid-circular-b/EuclidCircularB-Medium-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-Medium-WebS.woff2") format("woff2");
    font-style: normal;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 500;
    src: url("fonts/euclid-circular-b/EuclidCircularB-MediumItalic-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-MediumItalic-WebS.woff2") format("woff2");
    font-style: italic;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 600;
    src: url("fonts/euclid-circular-b/EuclidCircularB-Semibold-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-Semibold-WebS.woff2") format("woff2");
    font-style: normal;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 600;
    src: url("fonts/euclid-circular-b/EuclidCircularB-SemiboldItalic-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-SemiboldItalic-WebS.woff2") format("woff2");
    font-style: italic;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 700;
    src: url("fonts/euclid-circular-b/EuclidCircularB-Bold-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-Bold-WebS.woff2") format("woff2");
    font-style: normal;
  }

  @font-face {
    font-family: "Euclid Circular B";
    font-weight: 700;
    src: url("fonts/euclid-circular-b/EuclidCircularB-BoldItalic-WebS.woff") format("woff"),
      url("fonts/euclid-circular-b/EuclidCircularB-BoldItalic-WebS.woff2") format("woff2");
    font-style: italic;
  }
}

@import 'tippy.js/dist/tippy.css';
@import 'video.js/dist/video-js.css';