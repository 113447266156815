// This handles styles across ALL navs that use the "settings" sidebar_type.
// This includes account_nav, user_nav, invitation_nav, among other settings navs for specific areas that we will add in the future.

.sidebar.settings-sidebar {

  &.sidebar-open {
    @media (min-width: 768px) and (max-width: 1024px) {
      @apply relative shadow-none w-auto;
      max-width: 20rem;
    }// max-width: 768px

    .sidebar-inner-container {
      .sidebar-top {
        .close-mobile-sidebar {
          @apply mt-0 mr-0;

          @media (min-width: 768px) and (max-width: 1024px) {
            @apply hidden;
          }// max-width: 768px
        }// .close-mobile-sidebar
      }
    }
  }
  
  .sidebar-inner-container {

    .sidebar-top {
      @apply mt-7;

      a.home-link {
        @apply flex items-center font-medium ml-5;

        .home-link-icon {
          @apply w-7 text-surfaces-500 opacity-60;
        }// .home-link-icon

        .home-link-text {
          @apply text-surfaces-500;
        }// .home-link-text

        &:hover {
          .home-link-icon {
            color: rgb(var(--primary-600) / var(--tw-text-opacity));
            @apply opacity-100;
          }// .home-link-icon

          .home-link-text {
            @apply text-black;
          }// .home-link-text
        }
      }// .home-link

    }// .sidebar-top

    .settings-nav-sections {
      @apply items-start w-full space-y-12 mt-4;

      .settings-nav-section {
        @apply px-2;
        
        .settings-nav-section-header {
          @apply uppercase font-semibold text-xs tracking-wider text-surfaces-500 opacity-60 mb-4 px-3 ml-3px;
        }// .settings-nav-section-header

        .settings-nav-link {
          @apply flex items-center font-medium rounded-full border border-transparent py-1.5 px-3;

          .settings-nav-link-icon {
            @apply w-7 text-surfaces-500 opacity-70;
          }// .settings-nav-link-icon

          .settings-nav-link-text {
            @apply text-surfaces-500;
          }// .settings-nav-link-text

          &.active {
            // To apply the 'active' class on sidebar items, set @sidebar_active_item in the rails controller action for this view.
            // Set it to the name of this view (example: 'account-settings')
            // Then on the sidebar link, set its id to id='sidebar-link-{page-name}' (example: id='sidebar-link-account-settings')
            // sidebar/sidebar_controller.js will apply the active class to the link.

            @apply bg-white border-surfaces-500 border-opacity-40;

            .settings-nav-link-icon {
              color: rgb(var(--primary-600) / var(--tw-text-opacity));
              @apply opacity-100;
            }// .settings-nav-link-icon

            .settings-nav-link-text {
              @apply text-black;
            }// .settings-nav-link-text
          }// .settings-nav-link.active


          &:hover {
            .settings-nav-link-icon {
              color: rgb(var(--primary-600) / var(--tw-text-opacity));
              @apply opacity-100;
            }// .settings-nav-link-icon

            .settings-nav-link-text {
              @apply text-black;
            }// .settings-nav-link-text
          }
        }// .settings-nav-link

        .settings-nav-sub-section {

          .settings-nav-sub-section-links {
            @apply hidden;
          }
          
          &.active {
            // To apply the 'active' class on settings nav sub-sections, set @sidebar_active_item in the rails controller action for this view.
            // Set it to the name of this view (example: 'space-123')
            // Then on the .settings-nav-sub-section, set its id to id='sidebar-link-{page-name}' (example: id='sidebar-link-space-123')
            // sidebar/sidebar_controller.js will apply the active class to the link.

            @apply rounded-lg bg-white border border-surfaces-500 border-opacity-40;

            .settings-nav-link-icon {
              color: rgb(var(--primary-600) / var(--tw-text-opacity));
            }// .settings-nav-link-icon

            .settings-nav-link-text {
              @apply text-black;
            }// .settings-nav-link-text

            .settings-nav-sub-section-links {
              @apply block pb-4 pt-1 pl-9 space-y-2;

              .settings-nav-sub-section-link {

                .settings-nav-link-icon {
                  @apply text-surfaces-500 opacity-50;
                }// .settings-nav-link-icon

                .settings-nav-link-text {
                  @apply font-medium text-surfaces-500;
                }// .settings-nav-link-text

                &:hover,
                &.active {
                  // To apply the 'active' class on links within .settings-nav-sub-section-links, set @sidebar_active_sub_section_link in the rails controller action for this view.
                  // Set it to the name of this view (example: 'space-123-settings')
                  // Then on the .settings-nav-sub-section-link, set its id to id='sidebar-sub-section-link-{page-name}' (example: id='sidebar-sub-section-link-space-123-settings')
                  // sidebar/sidebar_controller.js will apply the active class to the link.

                  .settings-nav-link-icon {
                    color: rgb(var(--primary-600) / var(--tw-text-opacity));
                    @apply opacity-100;
                  }// .settings-nav-link-icon

                  .settings-nav-link-text {
                    @apply text-black;
                  }// .settings-nav-link-text
                }// .settings-nav-sub-section-link.active

              }// .settings-nav-sub-section-link
            }
          }// .settings-nav-sub-section.active

        }// .settings-nav-sub-section

      }// .settings-nav-section

    }// .settings-nav-sections

  }// .sidebar-inner-container

}// .sidebar.settings-sidebar