// Light Jumpstart theme for Pagy's default renderer

.pagination {
  @apply inline-flex flex-wrap items-center justify-start font-semibold text-base;

  .page {
    &:first-child a, &:first-child {
      @apply rounded-l-full;
    }

    &:last-child a, &:last-child {
      @apply rounded-r-full;
    }
  }

  .disabled, .gap {
    @apply border relative block leading-tight bg-white text-gray-400 no-underline py-2 px-3;
    margin-left: -1px;
  }

  .page {
    &.active {
      @apply border bg-gray-50 text-black relative block leading-tight no-underline py-2 px-3;
      margin-left: -1px;
    }

    a {
      color: rgb(var(--primary-600) / var(--tw-text-opacity));
      @apply border relative block leading-tight bg-white no-underline py-2 px-3;
      margin-left: -1px;

      &:hover {
        @apply bg-gray-100 text-black;
      }
    }
  }

  @media (max-width: 500px) {
    .page {
      @apply hidden;

      &.active {
        @apply hidden;
      }

      &.prev, &.next {
        @apply block;
      }
    }// .page
  }// max-width: 500px
}
