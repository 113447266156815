#messages, #message-templates-list, .message-template-container {

  .message, .message-template {
    &.editing-media {

      .video-js {

        .vjs-control-bar {
          display: flex;
        }// .vjs-control-bar

        .vjs-big-play-button {
          display: none;
        }// .vjs-big-play-button

        &.vjs-has-started {
          &.vjs-user-inactive {
            &.vjs-playing {
              .vjs-control-bar {
                opacity: 100; // ensure vjs control bar remains visible during playback while in edit mode
              }
            }
          }
        }

      }// .video-js

      .edit-recording-container {

        .edit-clips-timeline-container {
          position: relative;
          height: 24px;
          width: 100%;

          .edit-clips-timeline {
            height: 5px;
            margin-left: 129px;
            margin-right: 254px;
            top: -25px;
            position: relative;
            @-moz-document url-prefix() {
              margin-right: 193px;
            }

            .timeline-clip {
              width: 100px;
              height: 60px;
              top: -16px;
              position: absolute;
              transition: width 0.2s ease, left 0.2s ease;
              z-index: 100;

              .clip-crossout {
                background: repeating-linear-gradient(135deg,
                  #141d2b, #141d2b .25em /* black stripe */,
                  #cc1921 0, #cc1921 .4em /* blue stripe */
                );
                height: 20px;
                width: 100%;
                position: relative;
                top: 8px;
              }

              .clip-edges{
                width: 100%;
                height: 100%;
                box-sizing: border-box;

                .clip-edge {
                  width: 5px;
                  height: 60px;
                  border-radius: 2px;
                  background: #eb6066;
                  border: 1px solid #141d2b;
                  position: absolute;
                  top: 0;

                  &:hover {
                    background: #F5B70B;
                  }

                  &[title]:hover::after {
                    content: attr(title);
                    position: absolute;
                    top: -70%;
                    left: 0;

                    @apply bg-black rounded text-white font-medium py-1 px-2 text-sm bg-opacity-80;
                  }

                  &.clip-edge-left {
                    left: -2px;
                    cursor: ew-resize;
                  }
                  &.clip-edge-right {
                    right: -2px;
                    cursor: ew-resize;
                  }
                }
              }
            }// .timeline-clip

          }

        }// .edit-scrubber-container

      }// .edit-recording-container

    }// .message.editing-media
  }// .message

}// #messages