.message-thread-container {

  .thread-top {
    @apply hidden;
  }

  .threaded-messages {
    @apply hidden;
  }

  .thread-top-reply-buttons {
    @apply hidden;
  }

  .thread-bottom {
    @apply hidden;
  }

  .message {
    .message-settings {
      .reorder-message-within-thread {
        @apply hidden;
      }
    }
  }// .message
  
  &.thread-visible { // just means the thread has multiple messages in it.  doesn't mean the thread's container is visible to user (yet).  User sees thread's container when 'thread-open' class is present.

    &.thread-open {

      .thread-visible-container {
        @apply bg-gray-50 bg-opacity-30 rounded-lg border border-gray-200 border-opacity-50;

        .thread-top {
          @apply flex;
        }

        .thread-top-reply-buttons {
          @apply flex;
        }

        .thread-top-message-container {
          .message,
          .message-template {
            @apply px-4 md:px-8;
          }
        }// .thread-top-message-container

        .threaded-messages {
          @apply block;
        }// .threaded-messages

        .thread-bottom {
          @apply flex;
        }

        .message {
          .message-settings {
            .reorder-thread {
              @apply hidden;
            }
            .reorder-message-within-thread {
              @apply flex;
            }
          }

          .pinned-message-icon {
            @apply hidden;
          }
        }// .message

      }// .thread-visible-container

    }// .message-thread-container.thread-visible.thread-open

    &.draft-thread {
      &.thread-open {
        .thread-visible-container {
          background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
          border-color: rgb(var(--secondary-600) / var(--tw-border-opacity));
          @apply border-opacity-20;

          .thread-top {
            background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
            border-color: rgb(var(--secondary-200) / var(--tw-border-opacity));
            @apply bg-opacity-50;
          }
        }
      }
    }// .message-thread-container.draft-thread

    &.internal-thread {
      &.thread-open {
        .thread-visible-container {
          @apply bg-yellow-100 bg-opacity-30 border-yellow-100;

          .thread-top {
            @apply bg-yellow-100 bg-opacity-40 border-yellow-100;
          }
        }
      }
    }// .message-thread-container.internal-thread

  }// .message-thread-container.thread-visible

}// .message-thread-container