body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @apply text-gray-600;
}
.main {
  flex: 1;
}

html {
  @apply font-sans antialiased leading-normal;
  font-size: 14px;
}

@media (min-width: 420px) {
  html {
    font-size:16px;
  }
}

.turbolinks-progress-bar {
  background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));
}

a {
  color: rgb(var(--primary-600) / var(--tw-text-opacity));

  &:hover,
  &:focus {
    @apply text-black;
    transform: none;
  }
}

a strong {
  color: rgb(var(--primary-600) / var(--tw-text-opacity));

  &:hover,
  &:focus {
    strong {
      @apply text-black;
      transform: none;
    }
  }
}

h1, h2, h3, h4 {
  @apply font-headers text-black font-medium;
}

ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

hr {
  border-color: rgb(var(--surfaces-500) / var(--tw-border-opacity));
  @apply border-opacity-40;
}

.table {
  @apply w-full;
  th, td {
    @apply align-top;
  }

}

.table-responsive {
  @apply block w-full overflow-x-auto;
  -webkit-overflow-scrolling: touch;
}
