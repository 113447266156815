#conversations-index-content {

  .conversation {
    
    .unseen-dot {
      display: none;
    }

    h4 {
      @apply text-gray-700;
    }

    &.has-unseen-messages {

      .unseen-dot {
        display: block;
      }

      h4 {
        @apply text-black;
      }

    }// .conversation.has-unseen-messages

  }// .conversation

}