.radios-group {
  
  .radio-field {
    @apply border-gray-200;

    .icon-checked {
      display: none;
    }// .icon-checked

    .radio-field-icon {
      @apply text-gray-400;
    }// radio-field-icon

    .label-text {
      @apply text-gray-500;
    }// .label-text

    &.active {
      background-color: rgb(var(--primary-200) / var(--tw-bg-opacity));
      border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
      @apply z-10 bg-opacity-20;

      .icon-checked {
        @apply hidden md:block;
      }// .icon-checked

      .radio-field-icon {
        color: rgb(var(--primary-600) / var(--tw-text-opacity));
      }// radio-field-icon

      .label-text {
        @apply text-black;
      }// .label-text
    }
  }// .radio-field

}// .radios-group