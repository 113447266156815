.emoji-picker__wrapper {
  @apply z-100 #{!important};

  .emoji-picker {
    @apply font-sans antialiased shadow-lg bg-white rounded-md border border-gray-200 #{!important};

    .emoji-picker__search-container {
      @apply flex items-center #{!important};

      .emoji-picker__search {
        @apply rounded-full border-2 border-gray-200 text-black px-3 #{!important};

        &:focus {
          border-color: var(--primary-600) !important;
          @apply border border-2 #{!important};
        }
      }
    }

    .emoji-picker__category-button {
      @apply text-gray-400 #{!important};

      &:hover {
        @apply text-black cursor-pointer #{!important};
      }

      &.active {
        border-color: var(--primary-600) !important;
        @apply text-black #{!important};
      }
    }

    h2.emoji-picker__category-name {
      @apply pt-2 pl-3 text-gray-400 text-sm font-semibold tracking-wide #{!important};
    }

    .emoji-picker__container {
      .emoji-picker__emoji {
      }
    }

    .emoji-picker__preview {
      .emoji-picker__preview-emoji {
        @apply w-5 h-5 text-base #{!important};
      }
    }

  }// .emoji-picker

}// .emoji-picker__wrapper