#new-message-btn-top {
  &.show-on-short-viewport {
    @media (min-height: 1000px) {
      display: none;
    }// min-height: 1000px
  }
}

form#new_message,
form#new-conversation {
  trix-editor#message_text {
    min-height: 10rem;
  }
}

.new-msg-controls {

  .new-msg-control-btn {
    &.active {
      svg {
        @apply text-black;
      }
    }
  }// .new-msg-control-btn

  .message-type-menu {

    .active-type-pills {
      @apply divide-x-2 divide-gray-200 divide-opacity-60;

      .active-type-pill {
        @apply px-3;
      }// .active-type-pill
    }// .active-type-pills

    .message-type-menu-arrow {
      @apply ml-0;
    }// .message-type-menu-arrow

    .media-panel-toggles {

      &.grid-layout {
        @apply rounded-lg;

        .media-panel-toggles-container {
          @apply grid-cols-2;

          .media-panel-toggle {
            @apply flex-col h-24 rounded-md;

            .icon-checked {
              @apply -top-6px -left-6px;
            }
          }// .media-panel-toggles.grid-layout .media-panel-toggles-container .media-panel-toggle
        }// .media-panel-toggles.grid-layout .media-panel-toggles-container
      }// .media-panel-toggles.grid-layout

      &.linear-layout {
        @apply rounded-3xl;

        .media-panel-toggles-container {
          @apply grid-cols-1;

          .panel-icon {
            @apply w-5;
          }

          .media-panel-toggle {
            @apply space-x-3 h-12 rounded-full;

            .icon-checked {
              @apply -top-2px -left-2px;
            }
          }// .media-panel-toggles.linear-layout .media-panel-toggles-container .media-panel-toggle
        }// .media-panel-toggles.linear-layout .media-panel-toggles-container
      }// .media-panel-toggles.linear-layout

      .media-panel-toggle {
        border-color: rgb(var(--surfaces-100) / var(--tw-border-opacity));

        .icon-checked {
          display: none;
        }

        svg {
          @apply text-surfaces-500;
        }

        .label-text {
          @apply text-surfaces-500;
        }

        &:hover {
          @apply bg-white border-surfaces-500 border-opacity-20;

          svg {
            @apply text-surfaces-500;
          }

          .label-text {
            @apply text-surfaces-500;
          }
        }

        &.active {
          border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
          @apply bg-white;

          .icon-checked {
            display: block;
          }

          svg {
            color: rgb(var(--primary-600) / var(--tw-text-opacity));
          }

          .label-text {
            @apply text-black;
          }
        }
      }// media-panel-toggle
    }// .media-panel-toggles

    .message-block-toggle, 
    .panel-as-block-toggle {
      background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
      @apply border-surfaces-500 border-opacity-20 text-surfaces-500;

      .icon-checked { display: none; }

      .block-icon  {
        @apply text-surfaces-500 opacity-60;
      }

      .block-label {
        @apply text-surfaces-500;
      }

      &:hover {
        @apply bg-white border-surfaces-500 border-opacity-20;

        .block-icon  {
          @apply text-surfaces-500 opacity-60;
        }
      }

      &.active {
        border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
        @apply bg-white;
        .icon-checked { display: block; }

        .block-icon  {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
        }
        .block-label  {
          @apply text-black;
        }
      }
    }// .message-block-button

    &.truncated {
      .active-type-pills {
        @apply divide-none space-x-0;

        .active-type-pill {
          @apply px-0 pl-2.5;

          .pill-label {
            @apply hidden;
          }
        }// .active-type-pill
      }// .active-type-pills

      .message-type-menu-arrow {
        @apply ml-2.5;
      }
    }// .message-type-menu.truncated

  }// .message-type-menu

}// .new-msg-controls

.media-file-wrapper {
  .upload-text {
    display: block;
  }// .upload-text
  .replace-text {
    display: none;
  }// .replace-text

  &.has-file {
    .upload-text {
      display: none;
    }// .upload-text
    .replace-text {
      display: block;
    }// .replace-text
  }// &.has-file
}// .media-file-wrapper


.screen-camera-layout-selection {
  label {
    @apply text-gray-400;

    &.active {
      @apply text-black;
    }
  }
}

#timer {
  &.nearing-limit {
    @apply px-2 rounded-full font-semibold text-red-600;
  }
}


