.thumbnail-items {
  
  .thumbnail-item {
    
    .thumbnail-item-image {
      display: block;
      height: 0;
      width: 0;
      padding-bottom: calc(9/16 * 100%);
    }// .thumbnail-item-image

  }// .thumbnail-item

}// .thumbnail-items