#messages,
#program-items-list {
  .load-more-buttons-container {
    width: 100%;

    @media (min-width: 1024px) {
      padding: 4% 6%;
    }

    // min-width: 1024px
  }

  .message-thread-container {
    position: relative;
    width: 100%;

    .dummy-height {
      width: 100%;
      opacity: 0;
      padding: 4% 6%;
      transition: padding 0.5s ease-in-out;

      .dummy-top {
        @apply h-20;
      }

      .dummy-video {
        padding-bottom: calc(100% * (9 / 16));
      }

      .dummy-audio {
        padding-bottom: calc((100% * (3 / 20)) + 2rem);
        padding-top: 2rem;
        display: none;
      }

      .dummy-media-editor {
        @media (max-height: 1024px) {
          height: 340px;
        }

        @media (min-height: 1024px) {
          height: 170px;
        }
      }

      .dummy-text {
        @apply p-8;
      }

      .dummy-product-offer {
        @apply px-8 py-20;
      }

      .dummy-appointment-offer {
        @apply px-8 py-30;
      }

      .dummy-appointment-offer-expanded {
        @apply px-8 py-52;
      }

      .dummy-attachments {
        height: 140px;
      }

      .dummy-form {
        @apply px-8 py-20;

        .dummy-form-field {
          @apply h-52;
        }
      }

      .dummy-task {
        @apply py-12;
      }
    }

    .thread-outer-wrapper {
      padding: 4% 6%;
      transition: padding 0.5s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .thread-inner-wrapper {
        position: relative;
        padding-bottom: calc(100% * (9 / 16));
        width: 100%;
        height: 100%;
      }

      // .thread-inner-wrapper

      .embedded-media-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        padding-top: 25px;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .message {
        .unseen-dot {
          @apply -left-6 md:-left-10;
          display: none;
        }

        .message-settings {
          .mark-unseen-link {
            display: block;
          }

          .mark-seen-link {
            display: none;
          }

          .move-out-of-thread-link {
            @apply hidden;
          }

          .move-to-thread-link {
            @apply hidden;
          }
        }

        // .message .message-settings

        .load-more-messages-link {
          .thread-has-unseen-dot {
            @apply hidden;
          }
        }

        // .load-more-messages-link

        &.unseen {
          .unseen-dot {
            display: block;
          }

          .message-settings {
            .mark-unseen-link {
              display: none;
            }

            .mark-seen-link {
              display: block;
            }
          }

          // .message .message-settings
        }

        // .message.unseen

        &.only-message-in-thread {
          .message-settings {
            .move-to-thread-link {
              @apply flex;

              .move-to-another-thread {
                @apply hidden;
              }
            }
          }
        }

        // .message.only-message-in-thread

        &.newly-added-message {
          .dummy-height {
            display: none;
          }

          .thread-outer-wrapper {
            position: static;
            padding: 0;

            .thread-inner-wrapper {
              padding-bottom: 0;
            }
          }
        }

        // .message.newly-added-message
      }

      // .message
    }

    // .thread-outer-wrapper

    &.thread-has-unseen {
      // this class is applied if the thread has unseen messages (in the sub-thread, not top-message) for the current user
      .thread-outer-wrapper {
        .message {
          .load-more-messages-link {
            .thread-has-unseen-dot {
              @apply block;
            }
          }

          // .load-more-messages-link
        }

        // .message
      }

      // .thread-outer-wrapper
    }

    // .message-thread-container.thread-has-unseen

    &.thread-open {
      @apply mb-20;

      .dummy-height {
        display: none;
      }

      .thread-outer-wrapper {
        position: static;

        .thread-inner-wrapper {
          padding-bottom: 0;
        }

        // .thread-inner-wrapper

        .message {
          .unseen-dot {
            @apply -left-3.5 md:-left-5;
          }

          .message-settings {
            .move-out-of-thread-link {
              @apply flex;
            }

            .move-to-thread-link {
              @apply flex;
            }
          }

          // .message .message-settings

          .message-sub-actions {
            .load-more-messages-link-container {
              @apply hidden;
            }
          }

          // .message .message-sub-actions
        }

        // .message
      }

      // .thread-outer-wrapper
    }

    // .message-thread-container.thread-open

    &.focused,
    &.thread-open {
      .thread-outer-wrapper,
      .dummy-height {
        padding: 0;
      }
    }

    // .message-thread-container.focused, .message-thread-container.thread-open

    &.audio-media {
      .dummy-height {
        .dummy-video {
          display: none;
        }

        .dummy-audio {
          display: block;
        }
      }

      .thread-outer-wrapper {
        .thread-inner-wrapper {
          padding-bottom: calc((100% * (3 / 20)) + 2rem);
          padding-top: 2rem;
        }
      }
    }

    // .message-thread-container.audio-media

    &.no-media {
      .dummy-height {
        display: none;
      }

      .thread-outer-wrapper {
        position: static;
        padding: 4% 6% 0;

        .thread-inner-wrapper {
          padding-bottom: 0;
        }
      }
    }

    // .message-thread-container.no-media

    &.showing-transcript,
    &.editing-message,
    &.editing-media,
    &.message-scheduled {
      .dummy-height {
        display: none;
      }

      .thread-outer-wrapper {
        position: static;
        padding: 0;

        .thread-inner-wrapper {
          padding-bottom: 0;
        }
      }
    }

    // .message-thread-container.showing-transcript,
    // .message-thread-container.editing-message,
    // .message-thread-container.editing-media
  }

  // .message-thread-container

  // Always disable zoom effect for smaller screens
  @media screen and (max-width: 992px) {
    .message-thread-container,
    .message-thread-container.no-media {
      .dummy-height {
        display: none;
      }

      .thread-outer-wrapper {
        padding: 0;
        position: static;

        .thread-inner-wrapper {
          padding-bottom: 0;
        }
      }
    }

    // .message-thread-container
  }

  // media query
}

.hiding-seen-message-threads {
  #messages {
    .message-thread-container {
      &.all-messages-seen,
      &.draft-thread {
        @apply hidden;
      }
    }
  }
}

// .hiding-seen-message-threads

// Handling zoom effect (on/off)

// Set the sidebar toggle to show that zoom effect is on, by default
.sidebar {
  .scroll-zoom-on {
    @apply text-black;
  }

  .scroll-zoom-off {
    @apply text-gray-300;
  }
}

// User has disabled zoom effect.
body.disable-zoom-effect {
  // Disable the zoom effect
  #messages {
    .load-more-buttons-container {
      @media (min-width: 1024px) {
        padding: 0;
      }

      // min-width: 1024px
    }

    .message-thread-container {
      .dummy-height {
        display: none;
      }

      .thread-outer-wrapper {
        padding: 0;
        position: static;

        .thread-inner-wrapper {
          padding-bottom: 0;
        }
      }
    }

    // .message-thread-container
  }

  // #messages

  // Set the sidebar toggle to show that zoom effect is off
  .sidebar {
    .scroll-zoom-on {
      @apply text-gray-300;
    }

    .scroll-zoom-off {
      @apply text-black;
    }
  }
}

.attachment.attachment--content:has(.mentioned-user) {
  @apply inline-block;

  .mentioned-user {
    @apply py-0 px-1 relative bottom-2px;

    &.with-avatar {
      @apply -bottom-[8px];
    }

    .mentioned-user-name {
      @apply text-black;
    }
  }
}

// body.disable-zoom-effect
