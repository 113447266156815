#search-modal,
.search-library-in-message-creator {

  .select-search-type {
    .search-type {
      @apply rounded-full font-medium py-1 px-3 text-xs cursor-pointer border;

      &.inactive {
        border-color: rgb(var(--surfaces-100) / var(--tw-border-opacity));
        color: rgb(var(--surfaces-500) / var(--tw-text-opacity));

        &:hover {
          @apply text-black cursor-pointer;
        }
      }

      &.active {
        border-color: rgb(var(--surfaces-500) / var(--tw-border-opacity));
        color: rgb(var(--primary-600) / var(--tw-text-opacity));
        @apply bg-white border-opacity-20;
      }
    }
  }

  .search-results-group {

    a.search-result,
    .search-result {

      .search-result-box {

        .search-result-thumbnail {
          .search-thumbnail-icon-hover {
            display: hidden;
          }

          .search-thumbnail-icon-off {
            display: block;
          }

          .message-icon {
            color: rgb(var(--surfaces-500) / var(--tw-text-opacity));
          }

          &.has-thumbnail {
            .message-icon {
              @apply text-black;
            }
          }
        }

        .search-result-text {
        }
      }

      &:focus,
      &:hover,
      &.focused {

        .search-result-box {
          background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));

          .search-result-thumbnail {
            .search-thumbnail-icon-hover {
              display: block;
            }

            .search-thumbnail-icon-off {
              display: hidden;
            }

            .message-icon {
              color: rgb(var(--primary-600) / var(--tw-text-opacity));
            }
          }

          .search-result-text {
            h4 {
              color: rgb(var(--primary-600) / var(--tw-text-opacity));
            }
          }
        }

        // .search-result-text
      }

      // a.search-result:focus

    }

    // a.search-result

  }

  // .search-results-group

}

// #search-modal