.kickoff-recording {

  .toggle-device {
    @apply text-gray-400 bg-surfaces-500 bg-opacity-20;
    .icon-checked { display: none; }

    .device-icon  {
      @apply text-gray-400;
    }
    .device-label  {
      @apply text-gray-500;
    }

    &:hover {
      .device-label {
        @apply text-gray-500;
      }
    }

    &.active {
      border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
      background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));
      .icon-checked { display: block; }

      .device-icon  {
        color: rgb(var(--primary-600) / var(--tw-text-opacity));
      }
      .device-label  {
        @apply text-black;
      }
    }
  }// .toggle-device

  .screen-or-camera-wrapper {
    @apply space-x-4;
  }// .screen-or-camera-wrapper

  &.no-screen-camera-support {
    .screen-or-camera-wrapper {
      @apply space-x-1 bg-white mb-4;

      .toggle-device {
        @apply mb-0;
      }

      &.screen-selected {
        .toggle-device {
          &.camera-button {
            &.inactive {
              @apply bg-white;
            }
            .icon-checked {
              display: none;
            }
          }
        }
      }

      &.camera-selected {
        .toggle-device {
          &.screen-button {
            &.inactive {
              @apply bg-white;
            }
            .icon-checked {
              display: none;
            }
          }
        }
      }

      &.none-selected {
        @apply border border-surfaces-500 border-opacity-40;
        .toggle-device {
          &.screen-button,
          &.camera-button {
            @apply bg-white;
            .icon-checked {
              display: none;
            }
          }
        }
      }
    }// .screen-or-camera-wrapper
  }// .kickoff-recording.no-screen-camera-support

}// .kickoff-recording