.settings-sidebar {
  .sidebar-inner-container {
    .settings-nav-sections {
      .settings-nav-section {
        &#space-nav-section {

          .space-with-cohort-header {
            .settings-nav-section-header {
              @apply text-sm text-surfaces-500 opacity-60 mb-0;
            }// .settings-nav-section-header
            .cohort-header {
              @apply font-medium text-xs text-gray-400 mb-4 px-3 ml-3px;
            }// cohort-header
          }// .space-with-cohort-header

        }// .settings-nav-section#space_nav_section
      }// .settings-nav-section
    }// .settings-nav-sections
  }// .sidebar-inner-container
}// .settings-sidebar
