.hover-then-hide-flash {
  -webkit-animation: hideflash 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 3s;
  animation: hideflash 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
}
@-webkit-keyframes hideflash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes hideflash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}