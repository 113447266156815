#onboarding-survey {

  .ob-survey-checkboxes {
    
    .ob-survey-checkbox {

      .ob-survey-check-icon {
        display: none;
      }

      .ob-survey-checkbox-text {
        @apply text-gray-500;
      }

      &.active {
        @apply bg-opacity-50;

        .ob-survey-check-icon {
          display: block;
        }

        .ob-survey-checkbox-text {
          @apply text-black;
        }
      }

    }// .ob-survey-checkbox

  }// .ob-survey-checkboxes

}