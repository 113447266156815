@charset "UTF-8";

/* Trix 1.2.2 Copyright © 2019 Basecamp, LLC http://trix-editor.org
/*/
trix-editor {
  min-height: 5em;
  outline: none;
  @apply p-4 rounded-lg border border-gray-200 border-opacity-80 text-lg;

  @media (max-width: 1536px) {
    @apply text-base;
  }

  // max-width: 1536px (2xl size)

  &.message-text-editor {
    @apply p-6;
  }
}

trix-editor:empty:not(:focus)::before {
  @apply font-medium text-gray-400 opacity-60;
}

trix-editor strong {
  @apply text-black font-semibold;
}

trix-editor h1 {
  @apply text-2xl;
}

trix-editor ul,
trix-editor ol {
  @apply ml-7 list-outside;

  ul,
  ol {
    @apply ml-7;

    ul,
    ol {
      @apply ml-7;
    }
  }
}

trix-editor ul {
  ul {
    @apply list-circle;

    ul {
      @apply list-square;
    }
  }
}

trix-editor ol {
  ol {
    @apply list-alpha;

    ol {
      @apply list-roman;
    }
  }
}

trix-editor a {
  @apply font-medium;
}

trix-editor blockquote {
  @apply my-4 px-8 italic border-l-4 border-gray-100 border-opacity-80;
}

trix-editor pre {
  @apply p-8 rounded bg-gray-50 whitespace-pre-wrap text-base;
}

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection,
trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection,
trix-editor [data-trix-mutable] ::selection {
  background: none;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}

trix-editor .attachment.attachment--file {
  @apply inline-flex border border-gray-200 rounded-full py-2 px-4 font-medium;
}

trix-editor .attachment .attachment__caption .attachment__name {
  @apply font-medium text-black;
}

trix-editor .attachment .attachment__caption .attachment__size {
  @apply text-sm font-medium text-gray-400;
}

trix-editor .attachment.attachment--preview .attachment__caption {
  @apply flex flex-wrap justify-start text-left items-baseline space-x-4 pt-1;
}

trix-editor .attachment.attachment--preview .attachment__caption .attachment__name {
  @apply text-left text-base font-medium text-gray-600;
}

trix-editor .attachment.attachment--preview .attachment__caption .attachment__size {
  @apply text-left text-sm font-medium text-gray-400;
}

trix-editor .attachment:hover {
  cursor: default;
}

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}

trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}

trix-editor .trix-button-group {
  display: inline-flex;
}

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}

trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-editor .trix-button.trix-active {
  background: #cbeefa;
}

trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}

trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}

trix-editor .trix-button--remove:hover {
  border-color: #333;
}

trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}

trix-editor .attachment__metadata-container {
  position: relative;
}

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}

trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}