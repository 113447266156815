// Used for the Slack integration settings to set @mentions for slack notifications

.slack-mentions-menu {
  .slack-username {
    
    .slack-checkmark {
      display: none;
    }// .slack-checkmark

    &.mention-enabled {
      @apply text-black;
      
      .slack-checkmark {
        display: block;
      }// .slack-checkmark
    }
  }// .slack-username
}