#account-onboarding-modal {
  .tabs-list {
    .tab {
      @apply border-b border-r border-surfaces-500 border-opacity-20;

      h3 {
        @apply text-primary-600;
      }

      p {
        @apply text-surfaces-500;
      }

      .active-step-icon {
        @apply hidden;
      }

      &:hover {
        @apply bg-white border-r-transparent;

        h3 {
          @apply text-black;
        }

        .step-icon {
          @apply text-black;
        }
      }

      &.skipped,
      &.complete {
        h3 {
          @apply text-surfaces-500;
        }
      }

      &.active,
      &.active.skipped,
      &.active.complete {
        @apply bg-white border-r-transparent;

        h3 {
          @apply text-black font-semibold;
        }

        .step-icon {
          @apply hidden;
        }

        .active-step-icon {
          @apply inline-block;
        }
      }


    }
  }
}