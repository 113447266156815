// This handles the conversation sidebar navigation links

.sidebar.conversation-sidebar {
  @apply px-0;

  &.sidebar-open {
    max-width: 16rem;

    .sidebar-inner-container {

      .sidebar-top {
        .user-menu {
          @apply ml-3;

          .dropdown-menu {
            @apply left-2;
          }

          // .dropdown-menu    
        }

        // .user-menu  
      }

      // .sidebar-top

      .sidebar-sections {

        .sidebar-section {

          &.conversations-link {
            // The link that points back to all conversations (root)
            @apply -mt-16 mb-16;

            .section-link {
              .section-link-icon {
                svg {
                  @apply w-4;
                }
              }

              // .section-link-icon
              .section-link-text {
                @apply text-sm text-gray-300 -ml-2;
              }

              // .section-link-text

              &:hover {
                .section-link-text {
                  @apply text-black;
                }

                // .section-link-text
              }

              // .section-link:hover
            }

            // .section-link
          }

          // .sidebar-section.conversations-link

          &#conversation-sidebar-section {
            @apply rounded-l-none rounded-t-none rounded-br-lg;

            .section-link {
              // Hide the 'Messages' link when the sidebar is open
              @apply hidden;
            }

            // .section-link

            .section-settings {
              top: 1.4rem;

              .filter-unseen-messages-toggle {
                @apply w-5 h-5 rounded flex flex-col items-center justify-center;

                .filter-toggle-icon {
                  @apply text-gray-300;
                }

                // .filter-toggle-icon
                &:hover {
                  .filter-toggle-icon {
                    @apply text-black;
                  }

                  // .filter-toggle-icon
                }

                // .filter-toggle:hover
                &.active {
                  @apply text-white;
                  background-color: rgb(var(--secondary-600) / var(--tw-bg-opacity));

                  .filter-toggle-icon svg {
                    @apply text-white;
                  }

                  &:hover {
                    .filter-toggle-icon svg {
                      @apply text-white;
                    }
                  }
                }
              }

              .conversation-sidebar-options {
                .section-settings-icon {
                  svg {
                    @apply w-4;
                  }
                }
              }
            }

            // .section-settings

            #conversation-sidebar-links-container {
              max-height: calc(100vh - 18.5rem);
              overflow-x: visible;
              overflow-y: scroll;

              /* For Chrome, Edge, and Safari */
              &::-webkit-scrollbar {
                width: 4px;
                /* Adjust scrollbar width */
              }

              &::-webkit-scrollbar-track {
                @apply bg-transparent;
              }

              &::-webkit-scrollbar-thumb {
                @apply bg-gray-200;
                border-radius: 10px;
              }

              #conversation-sidebar-links {

                .thread-links-container {

                  .thread-meta-container {
                    @apply hidden;
                  }

                  // .thread-meta-container

                  .thread-messages {
                    .message-link-container {
                      .message-link {

                        .unseen-dot {
                          @apply hidden;
                        }

                        // .unseen-dot

                        &.unseen {
                          .unseen-dot {
                            @apply block -left-2.5 top-2.5;
                          }

                          // .unseen-dot
                        }

                        // .message-link.unseen

                        .load-thread-in-nav {
                          .thread-has-unseen-messages-dot {
                            @apply hidden;
                          }

                          // .thread-has-unseen-messages-dot
                        }

                        // .load-thread-in-nav

                      }

                      // .message-link  
                    }

                    // .message-link-container
                  }

                  // .thread-messages


                  &.active {
                    @apply bg-white rounded-lg border border-gray-200 -ml-2 -mr-1 mt-4;

                    .thread-meta-container {
                      @apply flex;
                    }

                    // .thread-meta-container

                    .thread-messages {
                      @apply pl-3 pr-2 pb-1;

                      .message-link-container {
                        .message-link {
                          .unseen-dot {
                            @apply -left-8px;
                          }

                          // .unseen-dot

                          .pinned-message-icon {
                            @apply hidden;
                          }
                        }

                        // .message-link
                      }

                      // .message-link-container
                    }

                    // .thread-messages
                  }

                  // .thread-links-container.active

                  &.thread-has-unseen {
                    .message-link-container {
                      .message-link {
                        .load-thread-in-nav {
                          .thread-has-unseen-messages-dot {
                            @apply block;
                          }

                          // .thread-has-unseen-messages-dot
                        }

                        // .load-thread-in-nav
                      }

                      // .message-link
                    }

                    // .message-link-container
                  }

                  // .thraed-links-container.thread-has-unseen

                }

                // .thread-links-container
              }

              // #conversation-sidebar-links

              &.overflowing-nav {
                box-shadow: inset 0px -9px 9px -10px rgba(0, 0, 0, 0.2);
              }

              // #conversation-sidebar-links-container.overflowing-nav

            }

            // #conversation-sidebar-links-container

            &.fixed-nav {
              @apply shadow-xl;

              @media (min-width: 768px) {
                @apply pl-0 fixed top-0 left-0;
                max-width: 16rem;
              }

              // min-width: 768px

              @media (min-width: 1024px) {
                @apply shadow-none;
              }

              // min-width: 768px

              #conversation-sidebar-links-container {
                max-height: calc(100vh - 8rem);
              }

              // #conversation-nav-links-container

            }

            // .sidebar-section#conversation-sidebar-section.fixed-nav

          }

          // .sidebar-section#conversation-sidebar-section

        }

        // .sidebar-section
      }

      // .sidebar-sections
    }

    // .sidebar-inner-container

  }

  // .sidebar.conversation-sidebar.sidebar-open

  &.sidebar-closed {
    @apply px-2;

    .sidebar-inner-container {
      .sidebar-sections {
        @apply space-y-4 mt-12;

        .sidebar-section {
          &#conversation-sidebar-section {
            .section-link {
              // Show the 'Messages' link when the sidebar is closed
              @apply flex;
            }

            // .section-link

            // Hide the messages nav when the sidebar is closed
            h3 {
              @apply hidden;
            }

            #conversation-sidebar-links-container {
              @apply hidden;
            }
          }

          // .sidebar-section#conversation-sidebar-section

          .new-message-sidebar-btn {
            @apply hidden;
          }
        }

        // .sidebar-section
      }

      // .sidebar-sections
    }

    // .sidebar-inner-container

    &.logged-out {
      .sidebar-inner-container {
        .sidebar-sections {
          @apply mt-2px;
        }
      }
    }

    // .sidebar.conversation-sidebar.sidebar-closed.logged-out
  }

  // .sidebar.conversation-sidebar.sidebar-closed

  &.short-screen {
    .sidebar-inner-container {
      .sidebar-sections {
        .sidebar-section {
          &#conversation-sidebar-section {
            #conversation-sidebar-links-container {
              max-height: calc(100vh - 14.5rem);
            }

            &.fixed-nav {
              #conversation-sidebar-links-container {
                max-height: calc(100vh - 4.2rem);
              }

              // #conversation-nav-links-container
            }

            // .sidebar-section#conversation-sidebar-section.fixed-nav
          }
        }
      }
    }
  }

  // .sidebar.short-screen

}

// .sidebar.conversation-sidebar

.hiding-seen-message-threads {
  .sidebar {
    &.conversation-sidebar {
      .sidebar-inner-container {
        .sidebar-sections {
          .sidebar-section {
            &#conversation-sidebar-section {
              #conversation-sidebar-links-container {
                #conversation-sidebar-links {
                  .thread-links-container {

                    &.all-messages-seen,
                    &.draft-thread {
                      @apply hidden;
                    }
                  }
                }
              }
            }

            // &#conversation-sidebar-section
          }

          // .sidebar-section
        }

        // .sidebar-sections
      }

      // .sidebar-inner-container
    }

    // &.conversation-sidebar
  }

  // .sidebar
}

// body.hiding-seen-message-threads


// Modify the sidebar height (when lots of message links) for various states (cta-banner shown, viewpoert is a short height, etc.)
.cta-banner-shown {
  .sidebar.conversation-sidebar {
    &.sidebar-open {
      .sidebar-inner-container {
        .sidebar-sections {
          .sidebar-section {
            &#conversation-sidebar-section {
              #conversation-sidebar-links-container {
                max-height: calc(100vh - 21.5rem);
              }

              &.fixed-nav {
                #conversation-sidebar-links-container {
                  max-height: calc(100vh - 8rem);
                }

                // #conversation-nav-links-container
              }

              // .sidebar-section#conversation-sidebar-section.fixed-nav
            }
          }
        }
      }
    }

    // .sidebar.conversation-sidebar.sidebar-open
    &.short-screen {
      .sidebar-inner-container {
        .sidebar-sections {
          .sidebar-section {
            &#conversation-sidebar-section {
              #conversation-sidebar-links-container {
                max-height: calc(100vh - 17.5rem);
              }

              &.fixed-nav {
                #conversation-sidebar-links-container {
                  max-height: calc(100vh - 4.2rem);
                }

                // #conversation-nav-links-container
              }

              // .sidebar-section#conversation-sidebar-section.fixed-nav
            }
          }
        }
      }
    }

    // .sidebar.conversation-sidebar.short-screen
  }

  // .sidebar.conversation-sidebar
}

// body.cta-banner-shown