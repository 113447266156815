.configure-onboarding {

  .onboarding-step {

    .onboarding-step-dot {
      background-color: rgb(var(--secondary-600) / var(--tw-bg-opacity));
    }// .onboarding-step-dot

    .onboarding-step-content {

      .onboarding-step-title {
        @apply text-black;
      }

      .toggle-step-content {
      }// .toggle-step-content

      .onboarding-step-sub-content {
        @apply block;
      } // .onboarding-step-sub-content

    }// .onboarding-step-content

    &.has-sub-content {

      &:hover {

        .onboarding-step-content {

          .onboarding-step-title {
            color: rgb(var(--secondary-600) / var(--tw-text-opacity));
          }

          .toggle-step-content {
            @apply text-black;
          }// .toggle-step-content

        }// .onboarding-step-content

      }// .onboarding-step.has-sub-content:hover

    }// .onboarding-step.has-sub-content

    &.disabled {

      .onboarding-step-dot {
        @apply hidden;
      }// .onboarding-step-dot

      .onboarding-step-content {

        .onboarding-step-title {
          @apply text-gray-400 italic;
        }

        .toggle-step-content {
        }// .toggle-step-content

        .onboarding-step-sub-content {
        } // .onboarding-step-sub-content

      }// .onboarding-step-content

    }// .onboarding-step.disabled

    &.hide-sub-content {

      .onboarding-step-content {

        .toggle-step-content {
          .toggle-step-content-icon {
            @apply transform rotate-180;
          }
        }// .toggle-step-content

        .onboarding-step-sub-content {
          @apply hidden;
        } // .onboarding-step-sub-content

      }// .onboarding-step-content

    }// .onboarding-step.hide-sub-content

  }// .onboarding-step

}// .configure-onboarding