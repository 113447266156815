#background-images-browser-modal {

  .selectable-image {

    .selected-icon {
      display: none;
    }

    .bg-image-div {
      @apply border-4 border-white ring-4 ring-white;
    }

    &.selected {

      .selected-icon {
        display: block;
      }

      .bg-image-div {
        @apply ring-4 ring-primary-600 border-2 border-white;
      }
    }

  }

}