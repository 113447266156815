.avatar {
  background-color: rgb(var(--secondary-200) / var(--tw-bg-opacity));
  @apply relative flex justify-center items-center rounded-full bg-opacity-70;

  img {
    @apply absolute rounded-full top-0 left-0;
  }

  .initials  {
    color: rgb(var(--secondary-600) / var(--tw-text-opacity));
    @apply uppercase w-full h-full flex justify-center items-center font-semibold tracking-wide leading-none;
  }// .initials 

  .account-badge {
    @apply absolute -bottom-0.5 -right-0.5 left-auto top-auto rounded-full;

    .initials {
      @apply text-white;
    }// .initials
  }

}// .avatar