.product-page {
  
  .product-page-body-content {

    .trix-content {
      @apply text-lg;

      @media (max-width: 768px) {
        @apply text-base;
      }// max-width: 768px (md size)
    }// .trix-content

  }// .product-page-body-content

  .cta-box {

    .trix-content {
      @apply text-lg;

      @media (max-width: 768px) {
        @apply text-base;
      }// max-width: 768px (md size)
    }// .trix-content

  }// .cta-box

}// .product-page

