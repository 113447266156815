/* Responsive YouTube Embeds */
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
}
  
.video-wrapper iframe,
.video-wrapper .dummy-video { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}

.animated-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.text-shadow {
  text-shadow: 0px 1px 1px rgba(136,17,21,.4);
}

@keyframes ellipsis {
  to {
    width: 1.1em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.1em;    
  }
}