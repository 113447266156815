.sidebar {

  &.sidebar-open {

    .sidebar-inner-container {
      
      .sidebar-sections {
        
        .sidebar-section.has-content {
          
          .section-content {
            
            .section-subnav {
              
              &.tags {

                .sub-section-subnav.tags-container {
                  @apply ml-0 pl-0;

                  .sidebar-tags-content {
                    
                    .tags-list {
                      @apply max-h-56 overflow-y-auto space-y-3px mr-4;

                      .tag {

                        .tag-content {
                          @apply flex items-center pl-2;

                          a.tag-link {
                            @apply flex items-center py-2px pr-3 pl-2 rounded-full border border-white;

                            svg.tag-icon {
                              @apply w-3.5 mr-2 transform rotate-90 text-gray-300;
                            }// .svg.tag-icon

                            .tag-name {
                              @apply text-gray-500 text-sm font-medium;
                            }// .tag-name

                            // Tailwind 3 couldn't compile this.  If it's still needed, then refactor to a different solution
                            // &.hidden {
                            //   display: none;
                            // }

                          }// .tag-link

                          .tag-settings {
                            @apply opacity-0 text-gray-400 ml-3;

                            svg {
                              @apply w-4;
                            }

                            &:hover {
                              @apply text-black;
                            }
                          }// .tag-settings

                        }// .tag-content

                        &.active {

                          .tag-content {
                            
                            a.tag-link {
                              background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
                              @apply border border-surfaces-500 border-opacity-20;

                              svg.tag-icon {
                                color: rgb(var(--primary-600) / var(--tw-text-opacity));
                              }// .svg.tag-icon

                              .tag-name {
                                @apply text-black;
                              }// .tag-name

                            }// .tag-link

                          }// .tag-content

                        }// .active

                        &:hover {

                          .tag-content {

                            a.tag-link {

                              svg.tag-icon {
                                color: rgb(var(--primary-600) / var(--tw-text-opacity));
                              }// .svg.tag-icon

                              .tag-name {
                                @apply text-black;
                              }// .tag-name

                            }// .tag-link

                            .tag-settings {
                              @apply opacity-100;
                            }// .tag-settings

                          }// .tag-content

                        }// .tag:hover

                      }// .tag

                    }// .tags-list

                  }// .sidebar-tags-content

                }// .tags-container

              }// .section-subnav.tags

            }// .section-subnav

          }// .section-content

        }// .sidebar-section.has-content

      }// .sidebar-sections

    }// .sidebar-inner-container

  }// .sidebar-open
  
}// .sidebar