.btn {
  @apply inline-flex font-semibold text-center px-4 py-0 rounded-full no-underline items-center justify-between outline-none;
  min-height: 40px;
  transition: ease 0.1s background, ease 0.1s transform, ease 0.1s color;

  svg {
    color: rgb(var(--primary-600) / var(--tw-text-opacity));
  }

  span {
    @apply font-medium;
  }

  &:hover,
  &:focus {
    @apply cursor-pointer outline-none;
  }

  &.btn-circular {
    @apply px-0 py-0 flex-col justify-center items-center min-h-0;
  }

}

.btn-small {
  @apply py-2 px-4 text-sm;
  min-height: 0;
}

.btn-large {
  @apply text-base px-6 py-4;
}

.btn-primary {
  @apply text-white;
  text-shadow: 0px 1px 1px rgba(136,17,21,.4);
  background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));

  .no-text-shadow {
    text-shadow: none;
  }

  svg {
    @apply text-white;
  }

  span {
    @apply text-white font-semibold;
  }
  
  &:hover, 
  &:focus {
    @apply text-white;
    text-shadow: 0px 0px 14px rgba(255,255,255,.4);

    .no-text-shadow {
      text-shadow: none;
    }

    svg {
      @apply text-white;
    }

    span {
      @apply text-white;
      text-shadow: 0px 0px 14px rgba(255,255,255,.4);
    }
  }

  &:active {
    @apply bg-opacity-70;
    text-shadow: none;
  }

  &.outline {
    color: rgb(var(--primary-600) / var(--tw-text-opacity));
    border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
    @apply bg-transparent border shadow-none;

    &:hover,
    &:focus {
      background-color: rgb(var(--primary-600) / var(--tw-bg-opacity));
      @apply text-white;
    }
  }
}

.btn-secondary {
  background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
  border-color: rgb(var(--surfaces-500) / var(--tw-border-opacity));
  color: rgb(var(--surfaces-500) / var(--tw-text-opacity));
  @apply border border-opacity-20 px-5 font-medium;

  span {
    @apply text-surfaces-500;
  }

  svg {
    @apply text-surfaces-500 opacity-60;
    
    &.de-emphasize {
      @apply opacity-60;
    }
  }

  &.emphasize {
    span {
      @apply text-black;
    }

    svg {
      @apply text-primary-600 opacity-100;
    }
  }

  &:hover,
  &:focus {
    @apply text-black;

    span {
      @apply text-black;
    }

    svg {
      color: rgb(var(--primary-600) / var(--tw-text-opacity));
      
      &.de-emphasize {
        color: rgb(var(--primary-600) / var(--tw-text-opacity));
      }
    }

    &.emphasize {
      @apply bg-opacity-60;
    }
  }

  

  &.outline {
    @apply bg-transparent border border-gray-600 text-gray-600 shadow-none;

    &:hover,
    &:focus {
      @apply bg-gray-500 text-white;
    }
  }
}

.btn-tertiary {
  @apply bg-gray-600 text-white;

  svg {
    @apply text-gray-300;
  }

  span {
    @apply text-white;
  }

  &:hover,
  &:focus {
    @apply bg-gray-500 text-white;

    span {
      @apply text-white;
    }
  }

  &.outline {
    @apply bg-transparent border border-gray-500 text-gray-500 shadow-none;

    &:hover,
    &:focus {
      @apply bg-gray-500 text-white;
    }
  }
}

.btn-clear {
  @apply bg-transparent text-gray-400;

  &:hover, 
  &:focus {
    @apply bg-gray-100 text-black;
  }
}

.btn-red {
  @apply bg-red-600 text-white;

  &:hover,
  &:focus {
    @apply bg-red-500 text-white;
  }

  &.outline {
    @apply bg-transparent border border-red-500 text-red-500 shadow-none;

    &:hover,
    &:focus {
      @apply bg-red-500 text-white;
    }
  }
}

.btn-white {
  @apply bg-white border border-gray-100;
}

.btn-link {
  @apply text-gray-500 bg-transparent;

  &:hover,
  &:focus {
    @apply text-gray-600;

    transform: none;
  }
}

.btn-expanded {
  @apply block w-full;
}

.btn-disabled {
  @apply opacity-30 cursor-default;
}
