@charset "UTF-8";

.trix-content {
  @apply text-lg;

  @media (max-width: 1536px) {
    @apply text-base;
  }

  // max-width: 1536px (2xl size)
}

.trix-content strong {
  @apply text-black font-semibold;
}

.trix-content h1 {
  @apply text-2xl;
}

.trix-content ul,
.trix-content ol {
  @apply ml-7 list-outside;

  ul,
  ol {
    @apply ml-7;

    ul,
    ol {
      @apply ml-7;
    }
  }
}

.trix-content ul {
  ul {
    @apply list-circle;

    ul {
      @apply list-square;
    }
  }
}

.trix-content ol {
  ol {
    @apply list-alpha;

    ol {
      @apply list-roman;
    }
  }
}

.trix-content a {
  @apply font-medium;
}

.trix-content blockquote {
  @apply my-4 px-8 italic border-l-4 border-gray-200;
}

.trix-content pre {
  @apply p-8 rounded bg-surfaces-100 rounded-lg whitespace-pre-wrap text-base;
}

.trix-content img {
  max-width: 100%;
  height: auto;
}

.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.trix-content .attachment a {
  text-decoration: none;
}

.trix-content .attachment a:hover,
.trix-content .attachment a:visited:hover {
}

.trix-content .attachment__caption {
  padding: 0;
  text-align: center;
}

.trix-content
  .attachment__caption
  .attachment__name
  + .attachment__size::before {
  content: " · ";
}

.trix-content .attachment.attachment--preview .attachment__caption {
  @apply flex flex-wrap justify-start text-left items-baseline pt-1;
}

.trix-content
  .attachment.attachment--preview
  .attachment__caption
  .attachment__name {
  @apply text-left text-base font-medium text-gray-600;
}

.trix-content
  .attachment.attachment--preview
  .attachment__caption
  .attachment__size {
  @apply inline-block mx-3 text-left text-sm font-medium text-gray-400;
}

.trix-content
  .attachment.attachment--preview
  .attachment__caption
  .attachment__size:before {
  content: "";
}

.trix-content .attachment.attachment--preview .attachment__caption a {
  @apply text-sm inline-block ml-3;
}

.trix-content .attachment--file {
  @apply border border-gray-200 rounded-full py-2 px-4 font-medium text-black;
}

.trix-content .attachment--file .attachment__caption .attachment__size:before {
  content: "";
}

.trix-content .attachment--file .attachment__caption .attachment__size {
  @apply text-left text-sm font-medium text-gray-400;
}

.trix-content .attachment--file .attachment__caption a {
  @apply text-sm;
}

.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  padding: 0;
}

.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 .attachment,
.trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content .mentioned-user {
  @apply inline-flex py-0 px-1 relative bottom-2px;
}
.trix-content .mentioned-user.with-avatar {
  @apply -bottom-[8px];
}
.trix-content .mentioned-user .mentioned-user-name {
  @apply text-black;
}
