.modal {

  &.modal-transition {
    @apply bg-transparent transition delay-1000 duration-500 transform;

    .modal-box {
      @apply opacity-0 transition delay-1000 duration-300 transform translate-y-32;
    }

    &.modal-transition-in {
      @apply bg-black bg-opacity-80;

      .modal-box {
        @apply opacity-100 translate-y-0;
      }

    }// .modal.modal-transition.modal-transition-in

  }// .modal.modal-transition

}// .modal