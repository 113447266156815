.multi-select {
  
  .multi-select-active-icon {
    @apply hidden;
  }// .filter-links-active-icon

  .multi-select-items {

    .multi-select-link {

      .multi-select-link-checkmark {
        @apply hidden;
      }// .multi-select-link-checkmark

      &.multi-select-link-active {
        @apply text-black;

        .multi-select-link-checkmark {
          color: rgb(var(--primary-600) / var(--tw-text-opacity));
          @apply block;
        }// .multi-select-link-checkmark
      }

      &.editable {
        &.multi-select-link-active {
        }// .multi-select-link.editable.multi-select-link-active
      }// .multi-select-link.editable

    }// .multi-select-link

    &::-webkit-scrollbar {
      // width: 6px;
    }

    &::-webkit-scrollbar-track {
      @apply bg-gray-50;
    }

    &::-webkit-scrollbar-thumb {
      @apply border-l-4 border-gray-200;
    }

  }// .multi-select-items

  &.multi-select-links-active {

    .multi-select-inactive-icon {
      @apply hidden;
    }// .multi-select-inactive-icon

    .multi-select-active-icon {
      @apply block;
    }// .multi-select-active-icon

    .multi-select-link {
      @apply text-gray-400;

      .multi-select-link-checkmark {
        @apply hidden;
      }// .multi-select-link-checkmark

      &.multi-select-link-inactive {
        @apply text-gray-400;
      }
      
      &.multi-select-link-active {
        @apply text-black font-semibold;

        .multi-select-link-checkmark {
          @apply block;
        }// .multi-select-link-checkmark
      }   
    }// .multi-select-link

  }// .multi-select-links-active

}// .multi-select-links