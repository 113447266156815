.form-group {
  @apply mb-6 relative;
}

/* non-input elements (like the Stripe card form) can be styled to look like an input */
div.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  padding-top: .5rem;
  padding-right: .75rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;

  @apply mt-1 block w-full rounded-lg border-gray-300 font-medium focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 focus:text-black;
}

.form-control {
  @apply block px-3 w-full rounded-lg border-gray-200 text-gray-500 placeholder-gray-400 ring ring-transparent;

  &:focus {
    border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
    @apply text-black outline-none;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) rgb(var(--primary-600));
  }

  &.error {
    @apply border-red-300 bg-red-50 text-red-700;
  }

  &.rounded-full {
    border-radius: 9999px;
  }

  &.read-only {
    @apply text-gray-400 focus:border-gray-200 focus:text-gray-400 focus:ring-0;
  }

  &.sm-rounded-l-none {
    @media (min-width: 640px) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    // min-width: 640px
  }

  &.md-rounded-l-none {
    @media (min-width: 768px) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    // min-width: 768px
  }

  &.lg-rounded-l-none {
    @media (min-width: 1024px) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    // min-width: 1024px
  }

  &.xl-rounded-l-none {
    @media (min-width: 1280px) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    // min-width: 1280px
  }
}

select.form-control {
  @apply text-sm font-medium;
}

label,
.label,
label:not(.forms-feature-label) {
  @apply block text-sm font-medium leading-5 text-black mb-1;

  &.checkbox-wrap {
    @apply opacity-100;

    span {
      @apply font-medium text-gray-500 relative top-1px;
    }
  }

  &.btn {
    @apply opacity-100;
  }
}

.form-hint {
  @apply text-gray-600 text-xs italic;

  &.error {
    @apply text-red-500 text-sm italic leading-normal mt-1;
  }

  &.success {
    @apply text-green-500 text-sm italic leading-normal mt-1;
  }
}

.hide-form-hint .form-hint {
  display: none;
}

.select {
  @apply block w-full mt-1 rounded-md border-gray-300 font-medium text-gray-500 shadow-sm;

  &:focus {
    border-color: rgb(var(--primary-600) / var(--tw-border-opacity));
  }
}

.caret {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800;
}

form.inline {
  .form-group {
    @apply flex justify-start items-center;
  }

  .form-group .input {
    @apply flex-1;
  }

  .form-group .label {
    @apply w-1/4;
  }
}

.form-checkbox {
  color: rgb(var(--primary-600) / var(--tw-text-opacity));
  @apply rounded border-gray-300 shadow-sm;

  &:focus {
    border-color: rgb(var(--primary-200) / var(--tw-border-opacity));
  }
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    background-color: rgb(var(--surfaces-100) / var(--tw-bg-opacity));
    @apply border border-surfaces-500 border-opacity-20 rounded-full flex items-center py-2 px-4 space-x-1 font-medium text-surfaces-500 opacity-100;

    svg {
      color: rgb(var(--primary-600) / var(--tw-text-opacity));
    }
  }

}

/* Used for the forms feature form fields */
.form-field {
  &.has-error {
    @apply bg-red-50;

    input,
    input.form-control {
      @apply border border-red-500;
    }
  }
}

.message-body {
  .form-field {
    &.has-error {
      @apply bg-red-100 border border-red-300;

      input,
      input.form-control {
        @apply border border-red-500;
      }
    }
  }
}
