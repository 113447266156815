@import "dropzone/dist/min/dropzone.min.css";
@import "dropzone/dist/min/basic.min.css";

.dropzone {
  @apply border-2 rounded-lg border-gray-200 border-dashed flex flex-wrap justify-center items-center;

  .dz-message {
    @apply font-medium text-gray-400 my-0 w-full py-8; 
  }

  &.dz-drag-hover {
    @apply border-2 rounded-lg border-gray-400 border-dashed;
  }

  &.dz-started {
    @apply pt-7;
  }// .dropzone.dz-started
    
  .dz-preview, 
  .dz-preview.dz-file-preview {

    .dz-image {
      background: none;
      @apply rounded-lg bg-gray-100 border border-gray-200;

      img {
        @apply object-cover min-h-full;
      }
    }

    .dz-progress {
      @apply top-auto bottom-8;

      .dz-upload {
        @apply bg-opacity-30;
        background-color: rgb(var(--secondary-600) / var(--tw-bg-opacity));
      }
    }

    .dz-details {
      .dz-size {
        span {
          @apply text-xs text-gray-500 bg-transparent;
        }
      }
      .dz-filename {
        @apply overflow-x-hidden relative -left-3px;
        span {
          @apply py-1 px-3 rounded-full bg-transparent font-medium text-black leading-8;

          &:hover {
            @apply bg-white border border-gray-200;
          }
        }

        &:hover {
          @apply overflow-x-visible left-0;
        }
      }
    }

    .dz-remove {
      @apply font-medium text-xs mt-1;

      &:hover {
        @apply no-underline text-black;
      }
    }

    .dz-error-message {
      color: white!important;
      top: 150px!important;
    }

    .dz-success-mark {
      display: none !important;
    }

  }// .dz-preview

}// .dropzone