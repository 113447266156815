form#new_message,
form.new-message,
form#new-conversation {

  &.internal-message {

    .new-msg-controls {
      @apply border-yellow-200;

      .new-msg-controls-content {
        @apply bg-yellow-100 text-yellow-600 bg-opacity-50;

        .new-msg-control-btn {
          &:hover,
          &.active {
            @apply border-yellow-300;
          }

          &.toggle-internal-btn {

            &:hover,
            &.active {
              svg {
                @apply text-yellow-600;
              }
            }
          }// .new-msg-control-btn.toggle-internal-btn

        }// .new-msg-control-btn

      }// .new-message-controls-content

    }// .new-msg-controls

    .message-border {
      @apply border-yellow-200;

      .message-body {

        .media-panel,
        .message-block {
          @apply bg-yellow-100 bg-opacity-50 text-yellow-600;
        }// .message-text

      }// .message-body
      
    }// .message-border

  }// form#new_message.internal-message

}// form#new_message


// Existing messages
#messages {

  .message {
    
    .internal-message-label {
      display: hidden;
    }

    .message-settings {
      .is-visible-msg-setting { display: block; }
      .is-internal-msg-setting { display: none; }
    }

    &.internal-message {

      .internal-message-label {
        display: flex;
      }

      .message-settings {
        .is-visible-msg-setting { display: none; }
        .is-internal-msg-setting { display: block; }
      }

      .message-border {
        @apply border-yellow-300;

        .message-body {

          .message-block {
            @apply bg-yellow-100 text-yellow-600 bg-opacity-50;
          }// .message-text

        }// .message-body
        
      }// .message-border

    }// .message.internal-message

  }// .message

} // #messages