.modal#purchase-modal {

  .trix-content {
    @apply text-base;

    @media (max-width: 1536px) {
      @apply text-sm;
    }// max-width: 1536px (2xl size)
  }

}