.sidebar {

  .clarityflow-callout {
    @apply fixed bottom-20 left-4; 

    .logo-link {
      @apply relative;

      a.logo {
        @apply h-12 absolute top-0 left-0 flex items-center rounded-full border border-transparent opacity-70;
        
        img {
          @apply h-6 opacity-40;

          &.logo-icon {
            @apply block;
          }

          &.logo-solid {
            @apply hidden;
          }

          &.logo-color {
            @apply hidden;
          }
        }

        &:hover {
          @apply opacity-100;

          img {
            @apply opacity-100;
            
            &.logo-icon {
              @apply hidden;
            }

            &.logo-solid {
              @apply hidden;
            }

            &.logo-color {
              @apply block;
            }
          }
        }// .logo:hover

      }// a.logo

    }// .logo-link

  }// .clarityflow-callout


  &.sidebar-open {
    .sidebar-inner-container {

      .clarityflow-callout {
        @apply left-8 bottom-10;

        @media (max-width: 1024px) {
          @apply static mt-20 ml-4 mb-8;
        }// max-width: 1024px

        .powered-by {
          @apply block mb-2 text-xs font-medium text-gray-300 opacity-70 ml-0 italic;
        }// .powered-by

        .logo-link {
          @apply inline-block mx-auto; 

          a.logo {
            @apply static h-auto; 

            .logo-icon {
              @apply hidden;
            }// .logo-icon

            .logo-solid {
              @apply block;
            }// .logo-solid

            &:hover {
              .logo-icon {
                @apply hidden;
              }// .logo-icon

              .logo-solid {
                @apply hidden;
              }// .logo-solid

              .logo-color {
                @apply block;
              }// .logo-solid

            }
          }// a.logo

        }// .logo-link
        
      }// .clarityflow-callout

    }// .sidebar-inner-container

    &.conversation-sidebar {
      .sidebar-inner-container {
        .clarityflow-callout {
          @apply bottom-3;

          &.no-account-present {
            @apply bottom-0;
            .powered-by {
              @apply mb-0;
            }// .powered-by
          }
        }// .clarityflow-callout  
      }// .sidebar-inner-container
    }// .sidebar.conversation-sidebar

  }// .sidebar.sidebar-open

  &.sidebar-closed {
    .sidebar-inner-container {

      .clarityflow-callout {

        .powered-by {
          @apply hidden;
        }// .powered-by

        .logo-link {

          a.logo {
            @apply w-12 justify-center;

            .logo-icon {
              @apply block text-gray-300;
            }// .logo-icon

            .logo-solid {
              @apply hidden;
            }// .logo-solid

            .logo-color {
              @apply hidden;
            }// .logo-color

            &:hover {
              @apply w-auto min-w-max pl-4 pr-4 shadow-lg border-gray-200 border-opacity-50 bg-white left-1px;

              .logo-icon {
                @apply hidden;
              }// .logo-icon

              .logo-solid {
                @apply hidden;
              }// .logo-solid

              .logo-color {
                @apply block;
              }// .logo-color

            }// a.logo:hover

          }// a.logo

        }// .logo-link
        
      }// .clarityflow-callout

    }// .sidebar-inner-container
  }// .sidebar.sidebar-closed

  &.short-screen {

    .clarityflow-callout {
      @apply absolute;
    }// .clarityflow-callout

    &.sidebar-open {

      .clarityflow-callout {
        @apply absolute;

        @media (max-width: 1024px) {
          @apply static mt-20 ml-4 mb-8;
        }// max-width: 1024px

      }// .clarityflow-callout

    }// .sidebar.short-screen.sidebar-open

  }// .sidebar.short-screen

}// .sidebar 